import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {
    FLOATING_CITY_LABEL, FLOATING_COUNTRY_LABEL, 
    REGISTRATION_STEP_1_PROMOTER, REGISTRATION_STEP_2_PROMOTER, REGISTRATION_STEP_3_PROMOTER,
    NO_CONTENT_AVAILABLE, USER_DATA_UPDATE_NAME_SURNAME_OR_COMPANY_TITLE
} from '../../../../../config/TextContent';
import {transformPropsWithLabelAndValue, onForeignObjectChange,
    onChangeHelper} from '../RegistrationModalHelper';
import {SLOVAK_COUNTRY_ID} from '../../../../../config/Constants';
import { cloneObject } from '../../../../../client/Helper';
import Select from 'react-select';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import RegistrationSubmitButton from '../RegistrationSubmitButton';
import RegistrationStep from '../../RegistrationStep/RegistrationStep'; 
import customReactSelectStyles from '../../../../../config/ReactSelectStyle';

class RoleRegisterModalPromoterContent extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                name: '',
                city: '',
                city_string: '',
                city_obj: null,
                country: 152,
                country_obj: {id: 152, label: "Slovensko", name: "Slovensko", value: 152},
            },
            errors: props.errors ? props.errors : {},
            hiddenFields:{
                'name': false,
                'country': true,
                'city': true,
                'submit': true
            }
        };

    }

    componentDidMount(){
        this.setState({
            errors: this.props.errors,
        });
    }

    onNameChange = (event) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let user = prevState.user;
            let errors = prevState.errors;
            user.name = event_value;
            errors.name = null;
            return {
                errors,
                user
            };
        });
    }

    onChange = (event, name) => {
        onChangeHelper(this, event, name);
    };

    onCountryChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'country');
    }

    onCityChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'city');
    }

    setVisibleSteps(...inputs){
        const diff = inputs.filter(input => {
            for(let view in this.state.hiddenFields){
                if(view === input)
                    return this.state.hiddenFields[view] !== input
            }
            return null;
        });
        if(diff.length > 0){
            this.setState((prevState) => {
                let hiddenFields = cloneObject(prevState.hiddenFields);
                diff.forEach(el => {
                    hiddenFields[el] = false;
                });
                return {...prevState, hiddenFields};
            });
        }
    }

    render() {
        return (
            <div>
                <div>
                    <RegistrationStep
                        step={1}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_1_PROMOTER} 
                        hiddenFields={this.state.hiddenFields}
                        >
                        <TextField
                            name="name"
                            required
                            value={this.state.user.name}
                            className={this.state.errors.name ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input register-modal__form__inputs_inputs-part__name-input__promoter error-textfield ' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input register-modal__form__inputs_inputs-part__name-input__promoter'}
                            label={this.state.errors.name ? this.state.errors.name : USER_DATA_UPDATE_NAME_SURNAME_OR_COMPANY_TITLE}
                            placeholder="..."
                            onChange={(event) => {this.setVisibleSteps('country', 'city'); this.onNameChange(event)}}
                            autoComplete="off"
                            autoCorrect="off"
                        />
                    </RegistrationStep>
                    <RegistrationStep
                        step={2}
                        stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                        stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                        content={REGISTRATION_STEP_2_PROMOTER}
                        hiddenFields={this.state.hiddenFields}
                        >
                        <div className={this.state.errors.country ? 'error-selectfield' : ''}>
                            <p className="selectfield-label required">{this.state.errors.country ? this.state.errors.country : FLOATING_COUNTRY_LABEL}</p>
                            <Select
                                styles={customReactSelectStyles}
                                classNamePrefix="styled-tree-select"
                                className="styled-tree-select styled-tree-select-registration-modal"
                                name="country"
                                value={this.state.user.country_obj}
                                onChange={(countryChange) =>  {this.setVisibleSteps('city'); this.onCountryChange(countryChange)}}
                                options={this.props.countries}
                                placeholder="..."
                                noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                            />
                        </div>
                    </RegistrationStep>
                    {
                        this.state.user.country ? (
                            <RegistrationStep
                            step={3}
                            stepTextClass="register-modal__content-wrapper__register-modal-content__point"
                            stepImgClass="register-modal__content-wrapper__register-modal-content__image"
                            content={REGISTRATION_STEP_3_PROMOTER}
                            hiddenFields={this.state.hiddenFields}
                            >
                                {
                                     this.state.user.country === SLOVAK_COUNTRY_ID ? 
                                        (
                                            <div className={this.state.errors.city ? 'error-selectfield' : ''}>
                                                <p className="selectfield-label required">{this.state.errors.city ? this.state.errors.city : FLOATING_CITY_LABEL}</p>
                                                <Select
                                                    styles={customReactSelectStyles}
                                                    classNamePrefix="styled-tree-select"
                                                    className="styled-tree-select styled-tree-select-registration-modal"
                                                    name="city"
                                                    placeholder="..."
                                                    value={this.state.user.city_obj}
                                                    onChange={selectedValue => {this.setVisibleSteps('submit'); this.onCityChange(selectedValue)}}
                                                    options={this.props.cities}
                                                    noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                                />
                                            </div>
                                        )
                                    :
                                        ( 
                                            <TextField
                                                required
                                                name="city_string"
                                                value={this.state.user.city_string}
                                                className={this.state.errors.city_string ? 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input error-textfield' : 'register-modal__form__input-text register-modal__form__inputs_inputs-part__name-input'}
                                                placeholder="..."
                                                onChange={event => {this.setVisibleSteps('submit'); this.onChange(event, null)}}
                                                label={this.state.errors.city_string ? this.state.errors.city_string : FLOATING_CITY_LABEL}
                                                autoComplete="off"
                                                autoCorrect="off"
                                            />
                                        )
                                }
                        </RegistrationStep> ) : ''
                    }
                </div>
                <div>
                    <RegistrationSubmitButton 
                        hidden={this.state.hiddenFields.submit}
                        loading={this.props.loading}
                        errors={this.props.errors}
                        onClick={() => 
                            this.props.onSubmit(this.state.user)}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countries: transformPropsWithLabelAndValue(state.countryReducer.countryList),
        cities: transformPropsWithLabelAndValue(state.citiesReducer.cityList),
    }
}

RoleRegisterModalPromoterContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(RoleRegisterModalPromoterContent);
