import React, {Component} from 'react';
import RegistrationModal from "../../components/Header/RegistrationModal/RegistrationModal/RegistrationModal";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setLoadingSetSelectedUser, setSelectedUser, setSelectedUserSuccess, setSelectedUserError, isLoggedInUserInLocalStorage} from "../../actions/UserActions";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import '../../components/Dashboard/Dashboard.css';
import LoggedUserDashboard from '../../components/Dashboard/LoggedUserDashboard/LoggedUserDashboard';
import SelectedUserDashboard from './SelectedUserDashboard';

class Dashboard extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params.username){
            this.props.setLoadingSetSelectedUser();
            this.props.setSelectedUser(this.props.match.params.username).then(() => this.props.setSelectedUserSuccess()).catch(() => this.props.setSelectedUserError());
        }
    }

    shouldRenderSelectedUser() {
        const param_in_url = this.props.match.params.username;
        const logged_user = this.props.user;

        if((!param_in_url) || 
            (param_in_url && logged_user && logged_user.hasOwnProperty('username') && logged_user.username === param_in_url))
            return false;
        return true
    }

    render() {
        return (
            <div className="dashboard">
            {
                isLoggedInUserInLocalStorage() ?
                (
                    this.props.user && !this.props.user.user_data ? (
                        <RegistrationModal />
                    ) : (
                    this.shouldRenderSelectedUser() ?
                        <SelectedUserDashboard loadingUser={this.props.loadingSelectedUser} /> 
                    : <LoggedUserDashboard loadingUser={this.props.loadingUser} />
                    )
                ) : 
                <SelectedUserDashboard loadingUser={this.props.loadingUser} /> 
            }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        authReducer: state.authReducer,
        userReducer: state.userReducer,
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser,
        loadingSelectedUser: state.userReducer.loadingSelectedUser,
        loadingUser: state.userReducer.loadingSelectedUser,
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        setLoadingSetSelectedUser,
        setSelectedUser,
        setSelectedUserSuccess,
        setSelectedUserError
    }, dispatch);
}

Dashboard.propTypes = {
    userReducer: PropTypes.object.isRequired,
    authReducer: PropTypes.object.isRequired,
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(Dashboard));
