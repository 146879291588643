import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import LoadingButton from '../LoadingButton/LoadingButton';
import './PreRegisterContent.css';
import {bindActionCreators} from "redux";
import {PASSWORD, PASSWORD_CONFIRMATION, IN_EVENTEEKO, CONFIRMATION_WITH_REGISTRATION,
    REGISTER_REGISTRATION, REGISTER_BUSINESS_CONDITIONS, REGISTER_REGISTER, REGISTER_FLOATING_LABEL_EMAIL, REGISTER_AND, REGISTER_PRIVACY_POLICIES,
    ERROR_REGISTER_FORM, EMAIL_ALREADY_USED,
    GET_USERTYPE_IN_GENITIVE, CONTENT_LOADING, ALT_ICON} from '../../config/TextContent';
import {isValidPasswordConfirm, isValidPassword, isValidEmail} from '../../client/Validation';
import {createUser, createUserError, loginSuccessfull} from '../../actions/UserActions';
import {loginError, login} from '../../actions/AuthActions';
import {getRole} from '../../client/Helper';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {toggleModalRegister} from '../../actions/ModalActions';    
import {Config} from '../../config/Constants';
import Button from '@material-ui/core/Button';

class PreRegisterContent extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                email: '',
                password: '',
                passwordConfirm: ''
            },
            errors: {
                email: null,
                password: null,
                passwordConfirm: null
            },
            loading: false,
            passwordVisible: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onPasswordVisible = this.onPasswordVisible.bind(this);
        this.submitPreRegisterModal = this.submitPreRegisterModal.bind(this);
        window.loadRecaptchaRegistration();
    }

    
    submitPreRegisterModal = () => {
        const role = getRole(this.props.registerModalType);
        const user = {
            email: this.state.user.email,
            plainPassword: this.state.user.password,
            roles: role ? [role] : [],
            "g-recaptcha-response": document.getElementById("g-recaptcha-response") ? document.getElementById("g-recaptcha-response").value : null
        };
        
        let errors = {};
        errors.email = isValidEmail(user.email);
        errors.password = isValidPassword(user.plainPassword);
        errors.passwordConfirm = isValidPasswordConfirm(user.plainPassword, this.state.user.passwordConfirm);

        if(!errors.email && !errors.password && !errors.passwordConfirm){
            this.setState({
                loading: true
            }, () => {
                this.props.createUser(user).then(response => {
                    window.loadRecaptchaRegistration();
                    if (response.payload.data.email_exists){
                        let errorsState = this.state.errors;
                        errorsState.email = EMAIL_ALREADY_USED; 
                        this.setState({errors: errorsState, loading: false});
                        this.props.createUserError();
                    } else {
                        this.clearInputs();
                        this.props.toggleModalRegister(false, true);
                        this.userRegistrationDefaultState();
                        window.scrollTo(0, 0);
                        this.props.history.replace("/home/uspesna-registracia");
                    }                   
                }).catch(() => {
                    window.loadRecaptchaRegistration();
                    this.props.createUserError();
                    this.userRegistrationDefaultState();
                });
            });
        }else
            this.setState({ errors });
    };

    userRegistrationDefaultState(){
        this.setState({ loading: false, errors: { email: ERROR_REGISTER_FORM, password: ERROR_REGISTER_FORM, passwordConfirm: ERROR_REGISTER_FORM } });
    }
  
    onPasswordVisible() {
        this.setState( prevState => {
            const newPasswordVisible = !prevState.passwordVisible;
            return { passwordVisible: newPasswordVisible };            
        });
    }

    onChange(event) {
        const event_target_name = event.target.name;
        const event_target_value = event.target.value;
        this.setState( prevState => {
            const user = prevState.user;
            const errors = prevState.errors;
            user[event_target_name] = event_target_value;
            errors[event_target_name] = null;
            return { user, errors };            
        });
    }

    clearInputs() {
        this.setState({
            user: {
                email: '',
                password: '',
                passwordConfirm: ''
            },
            errors: {
                email: null,
                password: null,
                passwordConfirm: null
            },
            loading: false
        });
    }

    componentDidMount() {
        if (!this.props.show)
            this.clearInputs();
    }

    render() {
        return (
            <div className="pre-register-content">
                <div className="pre-register-content__header row">
                    <div className="col-md-12">
                        <p className="pre-register-content__header__header-title"><span>{REGISTER_REGISTRATION} {GET_USERTYPE_IN_GENITIVE(this.props.registerModalType)}</span><span>{IN_EVENTEEKO}</span></p>
                    </div>
                </div>
                <div className="pre-register-content__form row">
                    <form action="post">
                        <div className="col-md-12">
                            <div className="pre-register-content__form__inputs">
                                <div className="pre-register-content__form__inputs-part">
                                    <TextField
                                        required
                                        id="email"
                                        spellCheck="false"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        fullWidth={true}
                                        name="email"
                                        placeholder="@"
                                        type="text"
                                        className={this.state.errors.email ? 'error-textfield' : ''}
                                        label={this.state.errors.email ? this.state.errors.email : REGISTER_FLOATING_LABEL_EMAIL}
                                        value={this.state.user.email}
                                        onChange={this.onChange}
                                    />
                                </div>
                                <div className="pre-register-content__form__inputs-part">
                                    <TextField
                                        required
                                        id="password"
                                        name="password"
                                        fullWidth={true}
                                        value={this.state.user.password}
                                        className={this.state.errors.password ? 'error-textfield' : ''}
                                        placeholder="*****"
                                        label={this.state.errors.password ? this.state.errors.password : PASSWORD}
                                        type={!this.state.passwordVisible ? "password" : "text"}
                                        onChange={this.onChange}
                                    />
                                    <Button
                                        type="button"
                                        className="passwordVisible"
                                        onClick={this.onPasswordVisible}
                                    >
                                        <i className={this.state.passwordVisible ? 'fa fa-eye pre-register-content__form__inputs-part-password_visible_icon active' : 'fa fa-eye pre-register-content__form__inputs-part-password_visible_icon'}/>
                                    </Button>
                                    <TextField
                                        required
                                        id="passwordConfirm"
                                        name="passwordConfirm"
                                        fullWidth={true}
                                        value={this.state.user.passwordConfirm}
                                        className={this.state.errors.passwordConfirm ? 'password_confirmation_class error-textfield' : 'password_confirmation_class'}
                                        label={this.state.errors.passwordConfirm ? this.state.errors.passwordConfirm : PASSWORD_CONFIRMATION}
                                        placeholder="*****"
                                        type={!this.state.passwordVisible ? "password" : "text"}
                                        onChange={this.onChange}
                                   />
                                   <input type="hidden" id="g-recaptcha-response" val=""/>
                                </div>
                            </div>
                            <div className="register__wrapper row">
                                <div className="col-md-1 col-xs-1">
                                    <img src={'/img/icons/info-green.svg'} className="register__principles_img" alt={ALT_ICON}/>
                                </div>
                                <div className="col-md-11 col-xs-11 register__wrapper__register__principles">
                                    <p className="register__wrapper__register_principles_p">{CONFIRMATION_WITH_REGISTRATION} 
                                        <Link to={Config.GENERAL_STATEMENT_ROOT} target="_blank" className="register__wrapper__register_principles__link">
                                            {REGISTER_BUSINESS_CONDITIONS}
                                        </Link> 
                                        {REGISTER_AND} 
                                        <Link to={Config.PRIVACY_POLICIES_ROOT} target="_blank" className="register__wrapper__register_principles__link">
                                            {REGISTER_PRIVACY_POLICIES}
                                        </Link>
                                    </p>
                                </div>
                            </div>
                            <div className="pre-register-content__form__button">
                                <LoadingButton 
                                    classes="pre-register-content__form__button__intro"
                                    paragraphClass="pre-register-content__form__button__intro-title"
                                    loaderClass="pre-register-content__form__button__react-loading"
                                    loading={this.state.loading}
                                    content={REGISTER_REGISTER}
                                    loadingContent={CONTENT_LOADING}
                                    onClick={() => this.submitPreRegisterModal()}
                                /> 
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

PreRegisterContent.propTypes = {
    registerModalOpened: PropTypes.bool.isRequired,
    registerModalType: PropTypes.number.isRequired,
    toggleModalRegister: PropTypes.func.isRequired,
    createUserError: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        registerModalOpened: state.modalReducer.registerModal,
        registerModalType: state.modalReducer.registerModalType
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        toggleModalRegister,
        createUser,
        createUserError,
        loginError,
        login,
        loginSuccessfull
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(PreRegisterContent));
