import React, {Component} from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import {GET_USER_ROUTE_TO_DASHBOARD} from '../../config/RoutesConstants'; 
import 'moment/locale/sk';
import {Link} from 'react-router-dom';
import {Config} from '../../config/Constants';
import {connect} from "react-redux";
import 'rc-tree-select/assets/index.css';
import 'rc-slider/assets/index.css';
import {bindActionCreators} from "redux";
import Fade from 'react-reveal/Fade';
import ReactLoading from 'react-loading';
import {setLoadingGetEventsForCalendar, getEventsForCalendar, getEventsForCalendarSuccess, getEventsForCalendarError} from '../../actions/EventActions';
import '../../components/Dashboard/Calendar/Calendar.css';
import { ALT_ICON, PREVIOUS_EVENT, NEXT_EVENT, ACTUAL_EVENT, ADD_EVENT, DETAIL_OF_EVENT, LOADING_CALENDAR_OF_EVENTS, THERE_ARE_NO_USER_EVENTS_AVAILABLE, CLOSE} from '../../config/TextContent';
import { isLoggedInUserInLocalStorage } from '../../actions/UserActions';
import {getUpdatedEventsForCalendar, formatDate, getUserTypeNumber} from '../../client/Helper';
import ReactTooltip from 'react-tooltip';

moment.locale("sk");
BigCalendar.momentLocalizer(moment);

let MyCustomHeader = (props) => {
    let label;
    switch(props.label){
        case "po":
            label="Pondelok";
            break;
        case "ut":
            label="Utorok";
            break;
        case "st":
            label="Streda";
            break;
        case "št":
            label="Štvrtok";
            break;
        case "pi":
            label="Piatok";
            break;
        case "so":
            label="Sobota";
            break;
        case "ne":
            label="Nedeľa";
            break;
        default: 
            break;
    }
    return (
        <div className="calendar__header-week">
          <div className="calendar__header-week-days">{label}</div>
        </div>
    )
}
class Calendar extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            view: 'month',
            date: new Date(),
            eventsForCalendar: [],
            openedDialog: false,
            selectedEvent: null,
            loadedEvents: false
        };
    }

    handleClickOpen = (event) => {
        this.setState({openedDialog: true, selectedEvent: event});
    };
    
    handleClose = () => {
        this.setState({openedDialog: false, selectedEvent: null});
    };

    getEvents = () => {
        let query_params = {};
        if(this.props.selectedUser && this.props.selectedUser.hasOwnProperty("id")){
            query_params.actual_user_id = this.props.selectedUser.id; 
            this.props.setLoadingGetEventsForCalendar();
            this.props.getEventsForCalendar(query_params).then((data) => {
                let updatedEventsForCalendar = getUpdatedEventsForCalendar(data.payload.data, this.props.selectedUser);
                this.setState({eventsForCalendar: updatedEventsForCalendar, loadedEvents: true});
                this.props.getEventsForCalendarSuccess();
            }).catch(() => {
                this.props.getEventsForCalendarError();
            });
        }
    }

    isSameDate = (firstDate, secondDate) => {
        return moment(firstDate).format("DD. MM. YYYY") === moment(secondDate).format("DD. MM. YYYY");
    };

    getEventsForDate = (date) => {
        let eventsInDate = [];
        let checkDate = moment(new Date(date)).startOf('day');
        let tmsp_checkDate = Date.parse(checkDate.toString());
        this.state.eventsForCalendar.forEach((event) => {
            if(event.end && !this.isSameDate(event.start, event.end)){
                let fromDate = moment(new Date(event.start)).startOf('day');
                let toDate = moment(new Date(event.end)).startOf('day');
                let tmsp_fromDate = Date.parse(fromDate.toString());
                let tmsp_toDate = Date.parse(toDate.toString());
                if(tmsp_checkDate >= tmsp_fromDate && tmsp_checkDate <= tmsp_toDate) {
                    if(this.props.selectedUser && this.props.selectedUser.hasOwnProperty("id") && this.props.selectedUser.id){
                        if(event.hasOwnProperty("user") && event.user && event.user.hasOwnProperty("id") && event.user.id === this.props.selectedUser.id){
                            eventsInDate.push(event); 
                        }else{
                            event.performers.forEach((performer) => {
                                if(performer.hasOwnProperty("user") && performer.user && performer.user.hasOwnProperty("id") && performer.user.id === this.props.selectedUser.id && performer.hasOwnProperty("event_interpret_time") && performer.event_interpret_time){
                                    let performer_date = moment(new Date(performer.event_interpret_time)).startOf('day');
                                    let tmsp_performer_date = Date.parse(performer_date.toString());
                                    if(tmsp_performer_date === tmsp_checkDate){
                                        eventsInDate.push(event);
                                    }
                                }
                            });
                        }
                    }
                }
            }else{
                if(this.isSameDate(date, event.start)){
                    eventsInDate.push(event); 
                }
            }
        });

        return eventsInDate;
    }

    onView = (view) => {
        this.setState({
            view: view
        })
    };

    onNavigate = (date) => {
        this.setState({
            date: new Date(date)
        });
    };

    componentDidMount = () => {
        this.getEvents();
    }


    render() {
        const DateCellWrapper = props => {
            let eventsInActualDate = this.getEventsForDate(props.value);
            return (
                eventsInActualDate.length > 3 ? (
                    <div className="custom-calendar-events__other-events">
                        <Link to={GET_USER_ROUTE_TO_DASHBOARD(this.props.selectedUser) + "/" + moment(new Date(props.value)).format("YYYY-MM-DD")} className="custom-date-cell-content__num-events">+ {eventsInActualDate.length - 2}</Link>
                    </div>
                ) : (
                    <div className="custom-calendar-events__other-events"></div>
                )
            );
        }

        const CustomEvent = (obj) => {
            let performer_time = obj.event.is_defined_exact_time ? moment(new Date(obj.event.start)).format("HH:mm") : "";

            return (
                <div className="custom-date-cell__event more-events-in-date" style={{
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0.45) 65%), url('+ Config.getEventMainPhoto(obj.event) +')'
                }}>
                    <div className="custom-date-cell__content">
                        {obj.event.is_user_performer ? (
                            <div className="custom-date-cell__content__text">
                                <div className="custom-date-cell-content__event-name rbc-event-label">  
                                    <img src={'/img/icons/star.svg'} alt={ALT_ICON} className="calendar__event-performer" data-tip data-for="calendar__event-performer"/>
                                    <ReactTooltip id="calendar__event-performer" place="bottom" effect="solid">{"Vystupujúci od " + performer_time}</ReactTooltip>
                                    {obj.event.event_title}
                                </div>
                            </div>
                        ) : (
                            <div className="custom-date-cell__content__text">
                                <div className="custom-date-cell-content__event-name rbc-event-label">  
                                    {obj.event.event_title}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )
        }

        const CustomToolbar = (toolbar) => {

            const goToBack = () => {
                toolbar.date.setMonth(toolbar.date.getMonth() - 1);
                toolbar.onNavigate('prev');
            };

            const goToNext = () => {
                toolbar.date.setMonth(toolbar.date.getMonth() + 1);
                toolbar.onNavigate('next');
            };
 
            const goToCurrent = () => {
                const now = new Date();
                toolbar.date.setDate(now.getDate());
                toolbar.date.setMonth(now.getMonth());
                toolbar.date.setYear(now.getFullYear());
                toolbar.onNavigate('current');
            };

            const label = () => {
                const date = moment(toolbar.date);
                return (
                    <span className="rbc-toolbar-label">{date.format('MMMM')} {date.format('YYYY')}</span>
                );
            };

            return (
                <div className="row calendar__elements">
                    <div className="col-md-7 calendar__elements__label-month-year">
                        <label>{label()}</label>
                    </div>
                    <div className="col-md-5 calendar__elements__back-actual-next-buttons">
                        <Button onClick={goToBack} className="calendar__elements__back-button">
                            <img src={'/img/icons/arrow-pointing-to-right-green.svg'} className="calendar__elements__button-icon calendar__elements__button-icon-left" alt={ALT_ICON}/>
                            <span className="calendar__elements__back-button__text">{PREVIOUS_EVENT}</span>
                        </Button>
                        <Button onClick={goToCurrent} className="calendar__elements__actual-button">{ACTUAL_EVENT}</Button>
                        <Button onClick={goToNext} className="calendar__elements__next-button">
                            <span className="calendar__elements__next-button__text">{NEXT_EVENT}</span>
                            <img src={'/img/icons/arrow-pointing-to-right-green.svg'} className="calendar__elements__button-icon calendar__elements__button-icon-right" alt={ALT_ICON}/>
                        </Button>
                    </div>
                </div >
            );
        };

        return (
            <div className="calendar__content">
                {this.props.loadingEventsForCalendar || this.props.loadingSelectedUser ?
                    <Fade duration={500} delay={250} >
                        <div className="photo-update-modal__content">
                            <div className="photo-update-modal__content-loading-wrapper">
                                <ReactLoading type="bubbles" color="#fff" className="photo-update-modal__loading-bubbles" />
                                <p className="photo-update-modal__loading-content loading-content__black">{LOADING_CALENDAR_OF_EVENTS}</p>
                            </div>
                        </div>
                    </Fade>
                : null}
                {!this.props.loadingEventsForCalendar && this.state.eventsForCalendar.length > 0 && this.state.loadedEvents ? (
                    <div className="container">
                        <div className="calendar__core">
                            <BigCalendar
                                style={{height: '763px'}}
                                startAccessor='start'
                                endAccessor='end'
                                culture='sk-SK'
                                onView={view => this.onView(view)}
                                views={['month','week','work_week','day']}
                                onSelectEvent={(event) => this.handleClickOpen(event)}
                                events={this.state.eventsForCalendar}
                                view={this.state.view}
                                date={this.state.date}
                                step={60}
                                onNavigate={date => this.onNavigate(date)}
                                components={{
                                    event: CustomEvent,
                                    toolbar: CustomToolbar,
                                    dateCellWrapper: DateCellWrapper,
                                    day: { header: DateCellWrapper},
                                    week: { header: MyCustomHeader },
                                    month: { header: MyCustomHeader },
                                }}
                            />
                            {this.state.selectedEvent ? (
                                <div className="calendar-event-modal">
                                    <div className="calendar-event-modal__position">
                                        <div className="calendar-event-modal__content">
                                            <div className="row">
                                                <div className="col-md-7 no-padding">
                                                    <div className="calendar-event-modal__content__photo" style={{backgroundImage: 'url(' + Config.IMAGE_EVENT + this.state.selectedEvent.main_photo_path + ')'}}></div>
                                                </div>
                                                <div className="col-md-5 no-padding">
                                                    <div className="calendar-event-modal__content__close-icon" onClick={this.handleClose}>
                                                        <img src="/img/icons/close-cross-green.svg" alt={CLOSE}/>
                                                    </div>
                                                    <div className="calendar-event-modal__content__event-info">
                                                        <h2>{this.state.selectedEvent.event_title}</h2>
                                                        <div className="row event-small__content__info">
                                                            <div className="col-md-1 col-xs-2 padding-left-0 padding-right-5"> 
                                                                <img src={'/img/icons/passage-of-time.svg'} className="event-small__content__info-icon-genre" alt={ALT_ICON} />
                                                            </div>
                                                            <div className="col-md-11 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0"> 
                                                                <p className="event-small__content__info-genre">
                                                                    {formatDate(new Date(this.state.selectedEvent.start))}
                                                                    {!this.state.is_defined_exact_time && this.state.selectedEvent.end && formatDate(new Date(this.state.selectedEvent.start)) !== formatDate(new Date(this.state.selectedEvent.end)) ? (" - " + formatDate(new Date(this.state.selectedEvent.end))) : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row event-small__content__info">
                                                            <div className="col-md-1 col-xs-2 padding-left-0 padding-right-5">
                                                                <img src="/img/icons/list.svg" className="event-small__content__info-icon-genre" alt={ALT_ICON}/>
                                                            </div>
                                                            <div className="col-md-11 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0">
                                                                <p className="event-small__content__info-genre">{this.state.selectedEvent.category}</p>
                                                            </div>
                                                        </div>
                                                        <div className="calendar-event-modal__content__event-info__description">
                                                            <p>{this.state.selectedEvent.additional_information}</p>
                                                        </div>
                                                        <Link to={'/event/' + this.state.selectedEvent.event_name_slug} className="calendar-event-modal__content__event-info__redirect-to-detail default_transparent_link">{DETAIL_OF_EVENT}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {isLoggedInUserInLocalStorage() && this.props.role !== null && this.props.role !== 4 ? (
                                <div>
                                    <Link to={Config.ADD_EVENT_ROOT} className="button-calendar">
                                        <img src={'/img/icons/close-cross-green.svg'} alt={ALT_ICON}/>
                                    {ADD_EVENT}</Link>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                {!this.props.loadingEventsForCalendar && this.state.loadedEvents && this.state.eventsForCalendar.length === 0 ? (
                     <div className="collection_event_lists__no-events padding-bottom-60-important">
                        <img src="/img/icons/close-cross-green.svg" alt={ALT_ICON} />
                        <p>{THERE_ARE_NO_USER_EVENTS_AVAILABLE}</p>
                    </div>
                ): null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        selectedUser: state.userReducer.selectedUser,
        loadingEventsForCalendar: state.eventReducer.loadingEventsForCalendar,
        loadingSelectedUser: state.userReducer.loadingSelectedUser,
        role: getUserTypeNumber(state.userReducer.user)
    }
}

function matchDispatchToProps(dispatch) {
    return bindActionCreators({
        setLoadingGetEventsForCalendar, 
        getEventsForCalendar, 
        getEventsForCalendarSuccess, 
        getEventsForCalendarError
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Calendar);
