import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './EventUserActions.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setLoadingLikeOrDislikeEvent, likeOrDislikeEvent, likeOrDislikeEventSuccess, likeOrDislikeEventError} from '../../../../actions/EventActions';
import {hasUserLikeOnEvent} from '../../../../client/Helper';
import {isLoggedInUserInLocalStorage} from '../../../../actions/UserActions';
import {Config} from '../../../../config/Constants';
import {withRouter} from 'react-router-dom';

class EventUserActions extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            like: hasUserLikeOnEvent(props.user, props.event),
        }
    }


    onClickEvent = () => {
        if(isLoggedInUserInLocalStorage()){
            this.setState(prevState => {
                return {
                    like: !prevState.like
                }
            }, () => {
                this.props.setLoadingLikeOrDislikeEvent();
                this.props.likeOrDislikeEvent(this.props.event.id).then(data=>{
                    if(data && data.payload && data.payload.data){
                        if(data.payload.data.like === true){
                            this.setState({like: true});
                        }else if(data.payload.data.like === false){
                            this.setState({like: false});
                        }
                        this.props.likeOrDislikeEventSuccess();
                    }
                }).catch((err) => {
                    this.props.likeOrDislikeEventError();
                });
            });
        }else{
            this.redirectToLogin();
        }
    }

    componentDidMount(){
        if(this.props.user && this.props.event){
            if(hasUserLikeOnEvent(this.props.user, this.props.event) === true)
                this.setState({like: true})
            else    
                this.setState({like: false})
        }
    }
    
    redirectToLogin = () => {
        this.props.history.push(Config.LOGIN_ROOT);
    }

    render(){
        let classEvent = this.props.classEvent ? this.props.classEvent : '';
        return (
            <div className={"table-event__publisher__icons " + classEvent}>
                {
                    /*
                    <Button className="table-event__publisher__icons__icon-send">
                        <img src={'/img/icons/send.svg'} alt="icon send"/>
                    </Button>
                    */
                }
                {!this.props.loginModal ? (
                    <Button className={"table-event__publisher__icons__icon-quality " + (this.state.like ? 'event-like' : "")} onClick={this.onClickEvent}>
                    {this.state.like ? 
                        <img src={'/img/icons/quality-green.svg'} alt="icon quality"/>
                    : <img src={'/img/icons/quality.svg'} alt="icon quality"/>
                    }
                    </Button>
                ) : null}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
    }
}

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({
        setLoadingLikeOrDislikeEvent,
        likeOrDislikeEvent,
        likeOrDislikeEventSuccess,
        likeOrDislikeEventError
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(EventUserActions));
