import React from 'react';
import {LOADING, MAIN_BAND, PERFORMER_DATE_AND_TIME, NO_OPTIONS_MESSAGE, PERFOMER_CREATE, PLACEHOLDER_PERFORMERS} from '../../../../../config/TextContent';
import ListItem from '@material-ui/core/ListItem';
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import {Config} from '../../../../../config/Constants';
import CreatableSelect from 'react-select/lib/Creatable';
import Radio from '@material-ui/core/Radio';
import customReactSelectStyles from '../../../../../config/ReactSelectStyle';

registerLocale("sk", sk);

const NewPerformerItem = (props) => {
    return(
        <ListItem className="performer-list__item new-performer-list__item">
            <div className="performer-list__publish-times padding-top-0-important">
                <Radio
                    checked={props.performer.is_main_band}
                    value={props.performer.id}
                    disableRipple={true}
                    name="is_main_band"
                    className="performer-list__publish-times__checkbox"
                    onChange={(event) => props.performerIsMainBandChange(event)}
                    color='secondary'
                    required={false}
                />
                <label>{MAIN_BAND}</label>
            </div>
            <p className={props.performer.errors && props.performer.errors.guestDate ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                {props.performer.errors && props.performer.errors.guestDate ? (PERFORMER_DATE_AND_TIME + " (" + props.performer.errors.guestDate + ")") : PERFORMER_DATE_AND_TIME}
            </p>
            <DatePicker
                openToDate={props.performer.guestDate ? props.performer.guestDate : props.eventDate}
                dateFormat="dd.MM.yyyy HH:mm"
                disabledKeyboardNavigation
                selected={props.performer.guestDate}
                minDate={props.eventDate}
                maxDate={props.eventDateEnd ? props.eventDateEnd : null}
                onChange={props.onDateOfCreatedPerformerChange}
                showTimeSelect
                timeIntervals="30"
                timeFormat="HH:mm"
                locale="sk"
            />
            <CreatableSelect
                styles={customReactSelectStyles}
                classNamePrefix="styled-tree-select"
                className="styled-tree-select"
                isClearable
                label={LOADING}
                onInputChange={props.handlePerformerInputChange}
                isLoading={props.tsAvailableUsersOpen && props.loadingAvailableUsers ? true : false}
                options={!props.loadingAvailableUsers && props.availableUsers ? Config.transformUsers(props.availableUsers) : null}
                value={props.performer.selectValue}
                placeholder={PLACEHOLDER_PERFORMERS}
                notFoundContent={LOADING}
                formatCreateLabel={
                    (inputValue) => {
                        return PERFOMER_CREATE + ": \"".concat(inputValue, "\"");
                    }
                }
                noOptionsMessage={() => NO_OPTIONS_MESSAGE}
                loadingMessage={() => LOADING}
                onChange={
                    (event) => {
                        if (event === props.availableUsers)
                            props.changeTsAvailableUsersOpen(true);
                        else
                            props.changeTsAvailableUsersOpen();
                        props.handlePerformerCreateItemChange(event);
                    }
                }
            />
        </ListItem>
    )
}

export default NewPerformerItem;
