import EndpointsUrl from "../../../../client/EndpointsUrl";
import axios from 'axios';
import Auth from "../../../../client/Auth";
import {validateUsername} from '../../../../client/Helper';

export const transformPropsWithLabelAndValue = (list) => {
    list.map((element) => {
        element.value = element.id;
        element.label = element.name;
        return element;
    });
    return list;
}

export const checkUsernameHelper = (that) => {
    that.setState((prevState) => {
        let errors = prevState.errors;
        errors.username = validateUsername(
            prevState.user.username,
            false, //available
            false, //valid
            false, //requestDone 
            true,  //requestWaiting
        );
        return {
            errors,
            requestDone: false,
            requestWaiting: true
        };
    }, () => {
        let query = '?username=' + that.state.user.username;
        axios.get(EndpointsUrl.getEndpointUrl(EndpointsUrl.SETTING_USERNAME, query), {
            headers: {
                Authorization: "Bearer " + Auth.getToken()
            }
        }).then((response) => {
            that.setState((prevState) => {
                let errors = prevState.errors;
                errors.username = validateUsername(
                    prevState.user.username,
                    response.data.available, 
                    response.data.valid, 
                    true, 
                    false,
                );

                return {
                    available: response.data.available,
                    valid: response.data.valid,
                    requestDone: true,
                    requestWaiting: false,
                    errors
                }
            });
        }).catch(() => {
            that.setState({
                requestDone: true,
                requestWaiting: false,
                valid: false,
                available: false
            })
        }); 
    });
};

export const onForeignObjectChange = (that, selectedOption, name) => {
    that.setState((prevState) => {
        let user = prevState.user;
        user[name] = selectedOption.id;
        user[name + '_obj'] = selectedOption;
        let errors = prevState.errors;
        errors[name] = null;
        console.log(user);
        return {
            errors,
            user
        } 
    });
}

export const transformObjectToTreeSelectObject = (obj) => {
    if(!obj) {
        return null;
    }
        
    return {
        id: obj.id,
        value: obj.id,
        label: obj.name,
        name: obj.name
    }
}

export const getGenderObjectById = (genders, gender_id) => {
    let i;
    for(i = 0; i < genders.length; i++) {
        let gender = genders[i];
        if(gender.hasOwnProperty('id') && gender.hasOwnProperty('name')){
            if(gender.id === gender_id){
                let return_obj = {
                    id: gender.id,
                    name: gender.name
                }
                return transformObjectToTreeSelectObject(return_obj);
            }
        }
    }
}

export const onChangeHelper = (that, event, name) => {
    let event_name = event.target.name;
    let event_value = event.target.value;

    that.setState((prevState) => {
        let user = prevState.user;
        let errors = prevState.errors;

        if (name !== null) {
            user[name] = event;
            errors[name] = null;
        } else {
            const field = event_name;
            user[field] = event_value;
            let errors = prevState.errors;
            errors[field] = null;
        }
        return {
            user,
            errors
        }
    });
};

export const setUsernameHelper = (that, value) => {
    that.setState((prevState) => {
        let user = prevState.user;
        user.username = value;
        return { user };
    });
}

export const getAttributeFromAddressArray = (parts_of_addresses=[], ...attrs) => {
    let matched_long_name = '';
    attrs.forEach(attr => {
        parts_of_addresses.forEach(element => {
            if(element.hasOwnProperty('types')){
                element.types.forEach(type => {
                        if(attr === type)
                            matched_long_name += element.long_name;
                });
            }
        });
    });
    
    return matched_long_name;
}

export const isAttributeInAddressArray = (parts_of_addresses=[], ...attrs) => {
    let found = false;
    attrs.forEach(attr => {
        parts_of_addresses.forEach(element => {
            if(element.hasOwnProperty('types')){
                element.types.forEach(type => {
                        if(attr === type)
                            found = true;
                });
            }
        });
    });
    
    return found;
}


export const getAttributeFromAddressArrayWithSeparator = (parts_of_addresses=[], separator=',', ...attrs) => {
    let matched_long_name = '';
    attrs.forEach(attr => {
        parts_of_addresses.forEach(element => {
            if(element.hasOwnProperty('types')){
                element.types.forEach(type => {
                        if(attr === type)
                            matched_long_name += (matched_long_name !== '' ? (separator + ' ') : '') + element.long_name;
                });
            }
        });
    });
    
    return matched_long_name;
}
