import React from 'react';
import {Link} from 'react-router-dom';
import './EventSmall.css';
import {GET_USER_ROUTE_TO_SPECIFIC_EVENT} from '../../config/RoutesConstants';
import { formatDate } from '../../client/Helper';
import {Config} from "../../config/Constants";
import {DELETE_ME_AS_EVENT_PERFORMER} from '../../config/TextContent';

const EventSmall = (props) => {
    const date_start_string = formatDate(new Date(props.event.event_date));
    const additional_information_string = props.event.additional_information && props.event.additional_information.length > 80 ? props.event.additional_information.slice(0, 80) + "..." : props.event.additional_information;
    const category_name = props.category.name && props.category.name.length > 50 ? props.category.name.slice(0, 50) + "..." : props.category.name;

    return (
        <div className="event-small-element">
            {
                props.specifier && props.specifier === "eventManagement" && (
                    <Link to={Config.EDIT_EVENT_ROOT + props.event_id} className="event-small__content__part-title__title">
                        <div className="event-small-element__image cursor-pointer" style={{backgroundImage:'url(' + Config.IMAGE_EVENT + props.event.main_photo_path +''}}></div>
                    </Link>
                )
            }
            {
                props.specifier && props.specifier === "eventPerformerRemoval" && (
                    <div className="event-small__content__part-title__title">
                        <div className="event-small-element__image" style={{backgroundImage:'url(' + Config.IMAGE_EVENT + props.event.main_photo_path +''}}>
                            <div onClick={() => props.userAsEventPerformerRemoval(props.event_id, props.event.event_name)} className="performer-removal-button">
                                <p>{DELETE_ME_AS_EVENT_PERFORMER}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !props.specifier && (
                    <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)}>
                        <div className="event-small-element__image cursor-pointer" style={{backgroundImage:'url(' + Config.IMAGE_EVENT + props.event.main_photo_path +''}}></div>
                    </Link>
                ) 
            }
            <div className="event-small__content">
                <div className="row event-small__content__part-title">
                    <div className="col-md-12">
                    {
                        props.specifier && props.specifier === "eventManagement" && (
                            <Link to={Config.EDIT_EVENT_ROOT + props.event_id} className="event-small__content__part-title__title cursor-pointer">
                                <span>{props.event.event_name}</span>
                            </Link>
                        )
                    }
                    {
                        props.specifier && props.specifier === "eventPerformerRemoval" && (
                            <div className="event-small__content__part-title__title">
                                <span>{props.event.event_name}</span>
                            </div>
                        )
                    }
                    {
                        !props.specifier && (
                            <Link to={GET_USER_ROUTE_TO_SPECIFIC_EVENT(props.event)} className="event-small__content__part-title__title cursor-pointer">
                                <span>{props.event.event_name}</span>
                            </Link>
                        ) 
                    }
                    </div>
                </div>
                <div className="row event-small__content__info padding-top-5">
                    <div className="col-md-2 col-xs-2"> 
                        <img src={'/img/icons/passage-of-time.svg'} className="event-small__content__info-icon-date" alt="icon clock"/>
                    </div>
                    <div className="col-md-10 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0"> 
                        <p className="event-small__content__info-date">{date_start_string}</p>
                    </div>
                </div>
                <div className="row event-small__content__info padding-top-5">
                    <div className="col-md-2 col-xs-2"> 
                        <img src={'/img/icons/list.svg'} className="event-small__content__info-icon-genre" alt="icon clock"/>
                    </div>
                    <div className="col-md-10 col-xs-10 padding-right-0-media padding-left-5-media padding-left-right-0"> 
                        <p className="event-small__content__info-genre">{category_name}</p>
                    </div>
                </div>
                <div className="row event-small__content__text">
                    <div className="col-md-12">
                        <p className="event-small__content__text__description">{additional_information_string}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventSmall;
