import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import { 
    FLOATING_COUNTRY_LABEL, FLOATING_CITY_LABEL, FLOATING_GENRE_LABEL,
    NO_CONTENT_AVAILABLE, ALT_ICON, LOADING, REQUIRED_TEXT_FIELD_LOWERCASE,
    USER_DATA_UPDATE_COUNTRY_GET_EVENTS, USER_DATA_UPDATE_CITY, USER_DATA_UPDATE_GENRE, INTERPRET_NAME_OR_BAND_NAME, INTERPRET_DATE_STARTED} from '../../../../config/TextContent';
import {SLOVAK_COUNTRY_ID} from '../../../../config/Constants';
import {transformPropsWithLabelAndValue, onForeignObjectChange, transformObjectToTreeSelectObject} from '../../RegistrationModal/RegistrationModal/RegistrationModalHelper';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import UserDataUpdateModalSubmitButton from '../../UserDataUpdateModal/UserDataUpdateModal/UserDataUpdateModalSubmitButton';
import customReactSelectStyles from '../../../../config/ReactSelectStyle';
import { listToTreeUniqueValues } from '../../../../client/Helper';
import TreeSelect from 'rc-tree-select';
import {getFilterEventMusicGenres} from '../../CreateEventModal/filters/filters';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class RoleUserDataManagementModalInterpretContent extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            user: {
                name: props.user.user_data.name,
                genre: props.user.user_data.genre && props.user.user_data.genre.hasOwnProperty('id') ? props.user.user_data.genre.id : null,
                city: props.user.user_data.city && props.user.user_data.city.hasOwnProperty('id') ? props.user.user_data.city.id : null,
                city_string: props.user.user_data.hasOwnProperty('city_string') ? props.user.user_data.city_string : '',
                city_obj: transformObjectToTreeSelectObject(props.user.user_data.city),
                country: props.user.user_data.state && props.user.user_data.state.hasOwnProperty('id') ? props.user.user_data.state.id : null,
                country_obj: transformObjectToTreeSelectObject(props.user.user_data.state),
                date_started: props.user.user_data?.date_started ? new Date(props.user.user_data?.date_started) : null
            },
            errors: props.errors ? props.errors : {},
            tsGenresOpen: false
        };
    }   

    componentDidMount(){
        this.setState({
            errors: this.props.errors,
        });
    }

    onTextfieldChange = (event, field_name) => {
        const event_value = event.target.value;
        this.setState((prevState) => {
            let user = prevState.user;
            let errors = prevState.errors;
            user[field_name] = event_value;
            errors[field_name] = null;
            return {
                errors,
                user
            };
        });
    }

    onDatefieldChange = (date, field_name) => {
        this.setState((prevState) => {
            let user = prevState.user;
            user[field_name] = date;
            return {
                user
            };
        });
    }

    onCityChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'city');
    }

    onCountryChange = (selectedOption) => {
        onForeignObjectChange(this, selectedOption, 'country');
    }

    onGenreChange = (value) => {
        this.setState((prevState) => {
            let user = {...prevState.user};
            let errors = {...prevState.errors};
            user['genre'] = value;
            if(value.length === 0){
                errors['genre'] = REQUIRED_TEXT_FIELD_LOWERCASE;
            }else{
                errors['genre'] = null;
            }
            return {errors, user};
        });
    }

    render() {
        return (
            <div>
                <div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/1.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{INTERPRET_NAME_OR_BAND_NAME}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <TextField
                                    required
                                    name="name"
                                    value={this.state.user.name}
                                    className={this.state.errors.name ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                    label={this.state.errors.name ? this.state.errors.name : INTERPRET_NAME_OR_BAND_NAME}
                                    placeholder="..."
                                    onChange={(event) => {this.onTextfieldChange(event, 'name')}}
                                    autoComplete="off"
                                    autoCorrect="off"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/2.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_GENRE}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <div className={this.state.errors.genre ? 'user-data-management-modal__step__body__element error-selectfield' : 'user-data-management-modal__step__body__element'}>
                                    <label className="required">{this.state.errors.genre ? this.state.errors.genre : FLOATING_GENRE_LABEL}</label>
                                    <div className="tree-select-wrapper tree-select-wrapper-registration-modal">
                                        <TreeSelect
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                            transitionName="rc-tree-select-dropdown-slide-up"
                                            choiceTransitionName="rc-tree-select-selection__choice-zoom"
                                            dropdownStyle={{ maxHeight: 200, overflow: 'auto', zIndex: 1500 }}
                                            placeholder="..."
                                            showSearch={false} 
                                            allowClear treeLine
                                            value={this.state.user.genre}
                                            inputIcon={<img src={'/img/icons/arrow-down-sign-to-navigate-white.svg'} 
                                            className="treeselect-arrow-down" alt={ALT_ICON} />}
                                            treeData={listToTreeUniqueValues(this.props.genres)}
                                            treeNodeFilterProp="genre"
                                            filterTreeNode={false}
                                            treeDefaultExpandAll={false}
                                            open={this.state.tsGenresOpen}
                                            notFoundContent={LOADING}
                                            onChange={(value) => {
                                                if (value === this.state.user.genre)
                                                    this.setState({ tsGenresOpen: true });
                                                else
                                                    this.setState({ tsGenresOpen: false });
                                                this.onGenreChange(value);
                                            }}
                                            onDropdownVisibleChange={(v) => {
                                                this.setState({
                                                    tsGenresOpen: v,
                                                });
                                                return true;
                                            }}
                                        /> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/3.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_COUNTRY_GET_EVENTS}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                <div className={this.state.errors.country ? 'user-data-management-modal__step__body__element error-selectfield' : 'user-data-management-modal__step__body__element'}>
                                    <label className="required">{this.state.errors.genre ? this.state.errors.genre : FLOATING_COUNTRY_LABEL}</label>
                                    <Select
                                        styles={customReactSelectStyles}
                                        classNamePrefix="styled-tree-select"
                                        className="styled-tree-select styled-tree-select-registration-modal"
                                        name="country"
                                        value={this.state.user.country_obj}
                                        onChange={(selectedValue) => {this.onCountryChange(selectedValue)}}
                                        options={this.props.countries}
                                        placeholder="..."
                                        noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/4.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{USER_DATA_UPDATE_CITY}</p>                                         
                            </div>
                        </div>
                        
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10">
                                {(((this.state.user.country && this.state.user.country.hasOwnProperty('id')) ? this.state.user.country.id : this.state.user.country) === SLOVAK_COUNTRY_ID) ? (
                                    <div className={this.state.errors.city ? 'user-data-management-modal__step__body__element error-selectfield' : 'user-data-management-modal__step__body__element'}>
                                        <label className="required">{this.state.errors.city ? this.state.errors.city : FLOATING_CITY_LABEL}</label>
                                        <Select
                                            styles={customReactSelectStyles}
                                            classNamePrefix="styled-tree-select"
                                            className="styled-tree-select"
                                            name="city"
                                            placeholder="..."
                                            value={this.state.user.city_obj}
                                            onChange={(selectedValue) => this.onCityChange(selectedValue)}
                                            options={this.props.cities}
                                            noOptionsMessage={() => {return NO_CONTENT_AVAILABLE}}
                                        />
                                    </div>
                                ) : (
                                    <TextField
                                        required
                                        name="city_string"
                                        value={this.state.user.city_string}
                                        className={this.state.errors.city_string ? 'user-data-management-modal__step__body__element error-textfield' : 'user-data-management-modal__step__body__element'}
                                        placeholder="..."
                                        onChange={(event) => {this.onTextfieldChange(event, 'city_string')}}
                                        label={this.state.errors.city_string ? this.state.errors.city_string : FLOATING_CITY_LABEL}
                                        autoComplete="off"
                                        autoCorrect="off"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row user-data-management-modal__step">
                        <div className="row user-data-management-modal__step__header">
                            <div className="col-md-2 col-xs-2">
                                <img src={'/img/icons/numbers/5.svg'} alt={ALT_ICON}/>
                            </div>
                            <div className="col-md-10 col-xs-10">
                                <p>{INTERPRET_DATE_STARTED}</p>                                         
                            </div>
                        </div>
                        <div className="row user-data-management-modal__step__body">
                            <div className="col-md-2 col-xs-2"></div>
                            <div className="col-md-10 col-xs-10 datepicker-wrapper">
                                <DatePicker
                                    dateFormat="dd.MM.yyyy"
                                    selected={this.state.user.date_started}
                                    disabledKeyboardNavigation
                                    onChange={(date) => {this.onDatefieldChange(date, "date_started")}}
                                    showTimeSelect={false}
                                    locale="sk"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-data-update-modal__button">
                    <UserDataUpdateModalSubmitButton 
                        loading={this.props.loading}
                        errors={this.props.errors}
                        onSubmit={() => this.props.onSubmit(this.state.user)}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        countries: transformPropsWithLabelAndValue(state.countryReducer.countryList),
        cities: transformPropsWithLabelAndValue(state.citiesReducer.cityList),
        genres: getFilterEventMusicGenres(state.eventReducer.eventMusicGenresList),
    }
}

RoleUserDataManagementModalInterpretContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired,
    cities: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(RoleUserDataManagementModalInterpretContent);
