import cloneDeep from 'clone-deep';
import moment from 'moment';
import {isLoggedInUserInLocalStorage} from '../actions/UserActions';
import {USERNAME_MIN_5_LENGTH, USERNAME_ALREADY_USED,
    BAD_FORMAT_USERNAME_INPUT, VERIFICATION_PROGRESS, GUEST_STARTS_BEFORE_EVENT_START_FUNC, GUEST_START_AFTER_EVENT_END_FUNC } from '../config/TextContent';
import {getAttributeFromAddressArray, isAttributeInAddressArray} from '../components/Header/RegistrationModal/RegistrationModal/RegistrationModalHelper';

export const getDates = (startDate, stopDate, returnDateObjects=false) => {
    if(!startDate && !stopDate)
        return [];

    if(!stopDate)
        return [!returnDateObjects ? moment(startDate).format('YYYY-MM-DD') : moment(startDate)];

    let dateArray = [];
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        const currDate = moment(currentDate);
        dateArray.push( !returnDateObjects ? currDate.format('YYYY-MM-DD') : currDate)
        currentDate.add(1, 'days');
    }
    return dateArray;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const existsDateInArray = (date, array_of_dates, date_key) => {
    let counter = 0;
    array_of_dates.forEach((element) => {
        if(moment(new Date(element[date_key])).format('YYYY-MM-DD') === date)
            counter++;
    });
    return counter > 0;
};

export const generateRandomString = (length = 5) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    for (let i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  
    return text;
}

export const list_to_tree = (list) => {
    let map = {}, node, roots = [], i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_id !== "0") {
            // if you have dangling branches check that map[node.parent_id] exists
            list[map[node.parent_id]].children.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export const listToTree = (data, options) => {
    options = options || {};
    let ID_KEY = options.idKey || 'id';
    let PARENT_KEY = options.parentKey || 'parent_id';
    let CHILDREN_KEY = options.childrenKey || 'children';

    let tree = [],
        childrenOf = {};
    let item, id, parentId;

    for (let i = 0, length = data.length; i < length; i++) {
        item = Object.assign({}, data[i]);
        if(!item)
            continue;

        id = item[ID_KEY];
        parentId = item[PARENT_KEY] || 0;
        // every item may have children
        childrenOf[id] = childrenOf[id] || [];
        // init its children
        item[CHILDREN_KEY] = childrenOf[id];
        if (parentId !== 0) {
            // init its parent's children object
            childrenOf[parentId] = childrenOf[parentId] || [];
            // push it into its parent's children object
            childrenOf[parentId].push(item);
        } else {
            tree.push(item);
        }
    };  
    return tree;
}

export const getListWithElementsParentIdNull = (data) => {
    let list = [];
    data.forEach((element) => {
        let new_obj = Object.assign({}, element);
        if(typeof new_obj.parent_id === 'undefined')
            list.push(new_obj);
    });
    return list;
}

export const listToTreeUniqueValues = (data, options) => {
    return getListWithElementsParentIdNull(listToTree(data, options));
}

export const filterBasicGenres = (data) => {
    let basicGenres = [];
    data.forEach(element => {
        if (element.id === element.main_category_id) {
            let copiedElement = cloneObject(element);
            copiedElement.children = null;
            basicGenres.push(copiedElement);
        }
    });
    return basicGenres;
}

export const filterChildrenGenres = (data, parentGenreId) => {
    let childrenGenres = [];
    data.forEach(element => {
        if (element.id === element.main_category_id && element.id === parentGenreId) {
            childrenGenres = element.children;
        }
    });
    return childrenGenres;
}

export const hasChildrenGenres = (data, genreId) => {
    let hasChildrenGenre = false;
    data.forEach(element => {
        if (element.id === element.main_category_id && element.id === genreId && element.children.length > 0) {
            hasChildrenGenre = true;
        } 
    });
    return hasChildrenGenre;
}

export const cloneObject = (obj) => {
    return cloneDeep(obj);
}

export const validateUsername = (username, available=false, valid=false, requestDone=false, requestWaiting=false, errorText='') => {
    if(!username)
        return USERNAME_MIN_5_LENGTH;
    if (username.length < 5)
        return USERNAME_MIN_5_LENGTH;
    else if (requestWaiting)
        return VERIFICATION_PROGRESS;
    else if (!available)
        return USERNAME_ALREADY_USED;
    else if (!valid)
        return BAD_FORMAT_USERNAME_INPUT;
    else if (errorText !== '')
        return errorText;
   
    return null;
}

export const isSameDay = (d1, d2) => {
    d1 = new Date(d1);
    d2 = new Date(d2);
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
}

export const dateSortAsc = (d1, d2) => {
    d1 = new Date(d1);
    d2 = new Date(d2);
    if(d1 > d2){
        return 1;
    }
    if(d1 < d2){ 
        return -1;
    }
    return 0;
}

export const mixDateWithTime = (date, time) => {
    if(!time)
        return null;
    const moment_data = moment(date);
    const moment_time = moment(time);
    let date_str = moment_data.format('YYYY-MM-DD');
    let time_str = moment_time.format('HH:mm');
    const mixedDate = new Date(date_str + " " + time_str);
    return mixedDate;
}

export const betweenStartEndPerfomersList = (dateStart, timeStart, dateEnd, timeEnd, element) => {
    const performersErrors = [];
    const guestTime = element.guestTime;
    const guestName = element.selectValue ? element.selectValue.label : null;
    if(dateStart && timeStart){
        const eventDateStart = moment(dateStart);
        const eventTimeStart = moment(timeStart);
        const guesTimeMoment = moment(guestTime);

        const dateStartDate = new Date(eventDateStart.format("YYYY-MM-DD") + " " + eventTimeStart.format("HH:mm"));
        const dateStartMoment = moment(dateStartDate);

        if(guesTimeMoment.diff(dateStartMoment) < 0)
            performersErrors.push(GUEST_STARTS_BEFORE_EVENT_START_FUNC(guestName));

        let constructed_end_date = null;
        if(dateEnd){
            constructed_end_date = moment(dateEnd);
            if(constructed_end_date)
                constructed_end_date = constructed_end_date.format("YYYY-MM-DD");
            if(!timeEnd)
                constructed_end_date += ' 23:59';
        }

        if(timeEnd)
            constructed_end_date = (constructed_end_date ? constructed_end_date : eventDateStart.format("YYYY-MM-DD")) + " " + moment(timeEnd).format("HH:mm");

        if(dateEnd || timeEnd)
            constructed_end_date = new Date(constructed_end_date);

        if(guesTimeMoment.diff(constructed_end_date) > 0)
            performersErrors.push(GUEST_START_AFTER_EVENT_END_FUNC(guestName));  
    }   
    return performersErrors;
}

export const getUserTypeNumber = (user) => {
    if(!user || !user.roles)
        return null;
    if (user.roles.includes('ROLE_BAND'))
        return 1;
    else if (user.roles.includes('ROLE_CLUB'))
        return 2;
    else if (user.roles.includes('ROLE_PROMOTER'))
        return 3;
    else
        return 4;
}

export const getRole = (modalType) => {
    let role = null;  
    switch (modalType) {
        case 1:
            role = 'ROLE_BAND'; break;
        case 2:
            role = 'ROLE_CLUB'; break;
        case 3:
            role = 'ROLE_PROMOTER'; break;
        case 4:
        default: 
            role = 'ROLE_USER';
            break;
    }
    return role;
}

export const getUsernameFromEmail = (string) => {
    if(string)
        return string.split("@")[0];
    return '';
}

export const getComposedStreet = (address_components) => {
    let str = '';
    if(isAttributeInAddressArray(address_components, 'route'))
        str = getAttributeFromAddressArray(address_components, 'route') + ' ';
    
    if(isAttributeInAddressArray(address_components, 'premise'))
        str += getAttributeFromAddressArray(address_components, 'premise');

    if(isAttributeInAddressArray(address_components, 'street_number')){
        if(isAttributeInAddressArray(address_components, 'premise'))
            str += '/';
        str += getAttributeFromAddressArray(address_components, 'street_number');
    }  
    return str;
}

export const getFullname = (user, userData) => {
    let userFullname = '';

    if(userData){
        let name = userData.name;
        let surname = userData.surname;
        userFullname = name + (surname !== '' && surname !== 'undefined' && surname ? ' ' + surname : '');
    }else{
        if(user){
            userFullname = user.username;       
        }
    }

    return userFullname;
}

export const formatDate = (date) => {
    if(!date)
        return '';

    let updated_date = date;
    let time = '';
    if(!(updated_date.getHours() === 0 && updated_date.getMinutes() === 0)){
        time = ("0" + updated_date.getHours()).slice(-2) + ":" + ("0" + updated_date.getMinutes()).slice(-2);
    }

    return (
        ("0" + updated_date.getDate()).slice(-2)  + 
        "." + 
        ("0" + (updated_date.getMonth()+1)).slice(-2) + 
        "." + updated_date.getFullYear() + " " + time   
    )
}

export const hasUserLikeOnEvent = (user, event) => {
    if(!user || !event)
        return false;  

    for(let i = 0; i < event.likes.length; i++){
        if(event.likes[i].user && event.likes[i].user.id === user.id){
            return true;
        }
    }
    return false;
}  

export const isSelectedUserProfile = (user, selectedUser) => {
    if((user && selectedUser && user.id !== selectedUser.id) || (!isLoggedInUserInLocalStorage() && selectedUser)){
        return true;
    }
    return false;
}

export const getUpdatedEventsForCalendar = (events, selectedUser) => {
    let updated_events = [];
    events.forEach((event) => {
        let is_user_performer = false;
        let is_defined_exact_time = false;
        let performer_date = null;
        if(selectedUser && selectedUser.hasOwnProperty("id") && event.hasOwnProperty("user") && event.user && event.user.hasOwnProperty("id") && event.hasOwnProperty("performers") && selectedUser.id !== event.user.id){
            event.performers.forEach((performer) => {
                if(performer.hasOwnProperty('user') && performer.user && performer.user.hasOwnProperty('id') && performer.user.id === selectedUser.id && !is_user_performer){
                    is_user_performer = true;
                    if(performer.hasOwnProperty("event_interpret_time") && performer.event_interpret_time){
                        is_defined_exact_time = true;
                        performer_date = performer.event_interpret_time;
                    }
                }
            });
        }

        let newEvent = {
            id: event.id,
            event_title: event.event_name,
            start: is_defined_exact_time ? new Date(performer_date) : new Date(event.event_date),
            end: event.event_date_end ? (!is_defined_exact_time ? new Date(event.event_date_end) : new Date(performer_date)) : new Date(event.event_date),
            main_photo_path: event.main_photo_path,
            event_name_slug: event.event_name_slug,
            user: event.user,
            category: event.category && event.category.hasOwnProperty("name") ? event.category.name : event.category_name,
            additional_information: event.additional_information,
            performers: event.performers,
            is_user_performer: is_user_performer,
            is_defined_exact_time: is_defined_exact_time
        };

        const start = moment(new Date(newEvent.start));
        const end = moment(new Date(newEvent.end));
        const hoursDifference = end.diff(start, 'hours');
        if (hoursDifference < 24) {
            newEvent.end = start.clone().startOf('day');
        }

        updated_events.push(newEvent);
    });

    return updated_events;
}

export const filterFutureEvents = (events) => {
    const today = new Date();
    events.sort(function(a, b) { return new Date(a.date) - new Date(b.date) });
    let finalEvents = [];
    events.forEach(element => {
        if (new Date(element.event_date) >= today) {
            finalEvents.push(element);
        }
    });
    return finalEvents;
}

export const filterPastEvents = (events) => {
    const today = new Date();
    events.sort(function(a, b) { return new Date(a.date) - new Date(b.date) });
    let finalEvents = [];
    events.forEach(element => {
        if (new Date(element.event_date) < today) {
            finalEvents.push(element);
        }
    });
    return finalEvents;
}
