import React from 'react';
import {LOADING, DELETE, MAIN_BAND, PERFORMER_DATE_AND_TIME, NO_OPTIONS_MESSAGE, PERFOMER_CREATE, PLACEHOLDER_PERFORMERS} from '../../../../config/TextContent';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DatePicker, {registerLocale} from "react-datepicker";
import sk from "date-fns/locale/sk";
import {Config} from '../../../../config/Constants';
import Radio from '@material-ui/core/Radio';
import CreatableSelect from 'react-select/lib/Creatable';
import customReactSelectStyles from '../../../../config/ReactSelectStyle';
import './PerformersList.css';

registerLocale("sk", sk);

const getValuePerformerSelectToEventUpdate = (obj) => {
    let obj_performer_select;
    if(obj){
        obj_performer_select = {'label': obj.name, 'value': obj.id};
    }
    return obj_performer_select;
}

const PerformersList = (props) => {
    return(
        props.performersList ? (
            props.performersList.map((element) => {
                return (
                    <ListItem className="performer-list__item" key={element.id}>
                        <div className="performer-list__publish-times padding-top-0-important">
                            <Radio
                                checked={element.is_main_band}
                                value={element.id}
                                disableRipple={true}
                                name="is_main_band"
                                className="performer-list__publish-times__checkbox"
                                onChange={(event) => props.performerIsMainBandChange(event)}
                                color='secondary'
                                required={false}
                            />
                            <label>{MAIN_BAND}</label>
                        </div>
                        <p className={element.errors && element.errors.guestDate ? "createEventModalContent__textfield label_color_red" : "createEventModalContent__textfield"}>
                            {element.errors && element.errors.guestDate ? (PERFORMER_DATE_AND_TIME + " (" + element.errors.guestDate + ")") : PERFORMER_DATE_AND_TIME}
                        </p>
                        <DatePicker
                            openToDate={element.guestDate ? element.guestDate : props.eventDate}
                            dateFormat="dd.MM.yyyy HH:mm"
                            disabledKeyboardNavigation
                            selected={element.guestDate}
                            minDate={props.eventDate}
                            maxDate={props.eventDateEnd ? props.eventDateEnd : null}
                            onChange={(date) => props.onDateOfPerformerChange(date, element.id)}
                            showTimeSelect
                            timeIntervals="30"
                            timeFormat="HH:mm"
                            locale="sk"
                        />
                        <CreatableSelect
                            styles={customReactSelectStyles}
                            classNamePrefix="styled-tree-select"
                            className="styled-tree-select"
                            onChange={
                                (event) => {
                                    if (event === props.availableUsers)
                                        props.changeTsAvailableUsersOpen(true);
                                    else
                                        props.changeTsAvailableUsersOpen();
                                    
                                    props.handlePerformerItemChange(event, element);
                                }
                            }
                            formatCreateLabel={
                                (inputValue) => {
                                    return PERFOMER_CREATE + ": \"".concat(inputValue, "\"");
                                }
                            }
                            isLoading={props.tsAvailableUsersOpen && props.loadingAvailableUsers ? true : false}
                            onInputChange={props.handlePerformerInputChange}
                            options={Config.transformUsers(props.availableUsers)}
                            value={props.identifier && props.identifier === 'eventDataManagement' && !element.selectValue ? getValuePerformerSelectToEventUpdate(element) : element.selectValue}
                            placeholder={PLACEHOLDER_PERFORMERS}
                            notFoundContent={LOADING}
                            isDisabled={element.disableSelect}
                            noOptionsMessage={() => NO_OPTIONS_MESSAGE}
                            loadingMessage={() => LOADING}
                        />
                        {!element.disableSelect ? (
                            <ListItemIcon className="performer-list__item__button">
                                <button type="button" className="performer-list__item__delete-button" onClick={() => props.deletePerformer(element.id)}>
                                    {DELETE}
                                </button>
                            </ListItemIcon>
                        ) : null}
                    </ListItem>
                );
            })
        ) : null
    )
}
    
export default PerformersList;
