/* eslint-disable no-undef */

import React, {Component} from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import {Config} from '../../../config/Constants';
import {SEARCHBOX_INPUT_CHOOSE_ADDRESS} from '../../../config/TextContent';
import './SearchboxInput.css';

class SearchBoxInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: ''
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.place) {
            this.setState({
                address: this.props.place
            }, () => {
                this.geoCodeByAddress(this.state.address);
            });
        }
    }

    handleSelect(address) {
        this.setState({
            address: address
        }, () => {
            this.geoCodeByAddress(address);
        });
    }

    handleChange(address) {
        this.setState({
            address: address
        });
    }

    geoCodeByAddress = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                this.props.onSelectAddressComponents(results[0].address_components);
                return getLatLng(results[0])
            })
            .then(({lat, lng}) => {
                this.props.onSelectAddressChange(address, {lat: lat, lng: lng});
            })
    }

    render() {
        const AutocompleteItem = ({ formattedSuggestion }) => (
            <div>
                <strong>{formattedSuggestion.mainText}</strong>{' '}
                <small className="text-muted">{formattedSuggestion.secondaryText}</small>
            </div>
        );

        const inputProps = {
            type: "text",
            value: this.state.address,
            onChange: this.handleChange,
            autoFocus: true,
            placeholder: SEARCHBOX_INPUT_CHOOSE_ADDRESS,
            name: 'search',
            id: "map-search",
        };

        const options = {
            location: new google.maps.LatLng(Config.SearchBoxInput.lat, Config.SearchBoxInput.lng),
            radius: Config.SearchBoxInput.radius,
        };

        return (
            <div>
                <PlacesAutocomplete
                    onSelect={this.handleSelect}
                    autocompleteItem={AutocompleteItem}
                    inputProps={inputProps}
                    options={options}
                    classNames={{
                        autocompleteContainer: "map-modal__autocomplete-container", 
                        autocompleteItem: "map-modal__autocomplete-container__item", 
                        autocompleteItemActive: "map-modal__autocomplete-container__active-item"}}
                />
                <div className='geocoding-results'>
                            {this.state.geocodeResults}
                        </div> 
                {
                    this.state.geocodeResults ?
                        <div className='geocoding-results'>
                            {this.state.geocodeResults}
                        </div> 
                :''}
            </div>
        )
    }
}

export default SearchBoxInput
