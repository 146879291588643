export const USER_SET_DATA_SUCCESS = 'Úspešne si vytvoril používateľa s menom: ';
export const UPDATE_USER_DATA_SUCCESS = 'Úspešne si upravil údaje používateľa s menom: ';
export const USER_SET_DATA_ERROR = 'Nastala chyba pri vytváraní používateľa';
export const UPDATE_USER_DATA_ERROR = 'Nastala chyba pri úprave používateľských údajov';
export const UPDATE_PROFILE_PHOTO_SUCCESS = 'Úspešne si zmenil profilovú fotografiu';
export const UPDATE_COVER_PHOTO_SUCCESS = 'Úspešne si zmenil titulnú fotografiu';
export const USER_DATA_UPDATE_NAME_SURNAME_OR_COMPANY_TITLE = 'Zadaj meno a priezvisko alebo názov spoločnosti';    
export const CHANGE_USER_DATA = 'Zmena užívateľských dát';
export const TYPES_OF_FINANCE = 'Typy vstupného';
export const NEW_FINANCE_ITEM = 'Ďalšia položka';
export const FLOATING_NAME_SURNAME_OR_COMPANY_TITLE_LABEL = 'Meno a priezvisko alebo názov spoločnosti';
export const PERSONAL_ADDRESS_REGISTRATION = 'Tvoja personálna adresa bude vyzerať: eventeeko.com/';
export const VERIFICATION_PROGRESS = 'Prebieha overovanie...';
export const BAD_FORMAT_USERNAME_INPUT = 'Nesprávny formát (5-30 znakov - musí obsahovať len písmená, čísla, bodky)';
export const USERNAME_ALREADY_USED = 'Používateľské meno už niekto používa';
export const USERNAME_AVAILABLE = 'Používateľské meno je k dispozícii';
export const DELETE_EVENT_FINANCE = 'Zrušiť vstupné';
export const INTERPRET_NAME_OR_BAND_NAME_REQUIRED = 'Meno interpreta je povinný údaj.';
export const INTERPRET_NAME_OR_BAND_NAME = 'Meno interpreta';
export const INTERPRET_DATE_STARTED = 'Rok vzniku';
export const TITLE_TYPE_OF_FINANCE = 'Názov';
export const INTERPRET_NAME_REQUIRED = 'Meno a priezvisko interpreta je povinný údaj.';
export const COUNTRY_NAME_REQUIRED = 'Štát je povinný údaj.';
export const CITY_NAME_REQUIRED = 'Mesto je povinný údaj.';
export const EVENT_INTERPRET_TIME_INVALID = 'Dátum a čas začiatku vystúpenia nie je v rozmedzí začiatok - koniec eventu';
export const MORE_FILTERS = 'Viac filtrov';
export const TOOLTIP_UPDATE_EVENT = 'Upraviť event';
export const TOOLTIP_DELETE_EVENT = 'Odstrániť event';
export const PERFORMER = 'Vystupujúci';
export const PROGRAM = 'Program';
export const OFFER_PRICE = 'Cena za ponuku';
export const PERFORMER_DATE_AND_TIME = 'Dátum a čas vystúpenia';
export const MAIN_BAND = 'Hlavný interpret';
export const HIDE_SHOWN_PERFORMERS_DATETIME = 'Skryť časy vystúpení';
export const SHOW_PERFORMERS_DATETIME = 'Zobraziť časy vystúpení';
export const SINGLE_ENTRANCE_FEE = "Jednotné vstupné";
export const EVENT_ADDRESS = 'Adresa eventu';
export const USER_HAS_NOT_PERMISSION_MODAL = 'Nemáš právo na túto akciu.';
export const DETAIL_OF_EVENT = 'Detail eventu';
export const LOADING_CALENDAR_OF_EVENTS = 'Načítavam kalendár eventov';
export const CALENDAR_OF_EVENTS = 'Kalendár eventov';
export const RESET_FILTERS = 'Reset filtrov';
export const EVENT_GENRE_NAME_REQUIRED = 'Žáner je povinný údaj.';
export const CLUB_NAME_REQUIRED = 'Názov klubu je povinný údaj.';
export const ADDRESS_REQUIRED = 'Adresa je povinný údaj.';
export const UPDATE_USER_DATA = 'Zmena užívateľských dát';
export const LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_1 = 'Spoznávaj, navštevuj a nájdi ';
export const LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_2 = 'všetky';
export const LOGIN_CONTENT_INTRO_PAGE_DESCRIPTION_3 = 'eventy na jednom mieste.';
export const UPDATE_PROFILE = 'Úprava profilu';
export const MORE_ABOUT_PORTAL = 'Objaviť Eventeeko';
export const INFO_BAD_LOGIN_DATA = 'Nesprávne meno alebo heslo.';
export const CLUB_TYPE_REQUIRED = 'Typ klubu je povinný údaj.';
export const PROMOTER_NAME_REQUIRED = 'Meno je povinný údaj.';
export const PROMOTER_SURNAME_REQUIRED = 'Priezvisko je povinný údaj.';
export const COMPANY_NAME_REQUIRED = 'Názov spoločnosti je povinný údaj.';
export const NAME_REQUIRED = 'Meno je povinný údaj.';
export const SURNAME_REQUIRED = 'Priezvisko je povinný údaj.';
export const GENDER_REQUIRED = 'Pohlavie je povinný údaj.';
export const NO_CONTENT_AVAILABLE = 'Žiadny výsledok';
export const CLOSE_USER_DATA_UPDATE_MODAL = 'Ukončiť úpravy užívateľa';
export const USERNAME_REQUIRED = 'Používeteľské meno je povinný údaj.';
export const LIST_OF_EVENTS = 'Zoznam eventov';
export const FILTERING_OPTIONS = 'Možnosti filtrovania';
export const PREVIOUS_EVENT = 'predchádzajúci';
export const NEXT_EVENT = 'nasledujúci';
export const ACTUAL_EVENT = 'aktuálny';
export const THERE_ARE_NO_CLUBS_AVAILABLE = 'Nie sú k dispozícii žiadne kluby.';
export const THERE_ARE_NO_BANDS_AVAILABLE = 'Nie sú k dispozícii žiadni interpreti.';
export const PLACE_FULL_STRING_REQUIRED = 'Celá adresa je povinná';
export const FIELD_IS_REQUIRED = 'pole je povinné';
export const EVENT_END_DATE_INVALID_SMALLER_THAN_START_DATE = 'dátum  eventu nesmie byť menší ako začiatok eventu';
export const EVENT_END_DATE_INVALID_FIRST_START_DATE = 'Najprv musí byť zadaný dátum začiatku eventu';
export const USERNAME_BAD_FORMAT = 'Zadali ste neplatný formát mena.';
export const USERNAME_MIN_5_LENGTH= 'Používateľské meno musí mať minimálne 5 znakov.';
export const REGISTRATION_DISMISS_MODAL = 'Ukončiť registráciu';
export const TRANSLATE_ENTRY = 'Vstup';
export const TEXT_FINISH_UPLOADING_EVENT_IMAGES = 'Dokončiť pridávanie fotografií';
export const PHOTOGRAPHY_TRANSLATE = 'Fotografia';
export const FLOATING_USERNAME_LABEL = 'Používateľské meno';
export const FLOATING_NAME_LABEL = 'Meno';
export const FLOATING_SURNAME_LABEL = 'Priezvisko';
export const FLOATING_GENDER_LABEL = 'Pohlavie';
export const FLOATING_COMPANY_NAME = 'Meno interpreta';
export const MAN_TRANSLATE = 'Muž';
export const WOMAN_TRANSLATE = 'Žena';
export const FLOATING_CITY_LABEL = 'Mesto';
export const FLOATING_COUNTRY_LABEL = 'Štát';
export const PROMOTER_FIRSTNAME = 'Meno promótera';
export const PROMOTER_SURNAME = 'Priezvisko promótera';
export const GENERAL_STATEMENT = 'Obchodné podmienky';
export const PRIVACY_POLICIES = 'Ochrana osobných údajov';
export const GENERAL_STATEMENT_INTRO_TEXT = 'Nullam sagittis sed urna quis vestibulum.';
export const PRIVACY_POLICIES_INTRO_TEXT = '';
export const COMPANY_NAME = 'Názov spoločnosti';
export const INTERPRET_TRANSLATE = 'Interpret';
export const COMPANY_NAME_OR_CLUB_NAME = 'Meno interpreta';
export const FLOATING_COMPANY_NAME_OR_INTERPRET_NAME = 'Meno interpreta';
export const INTERPRET_NAME = 'Meno a priezvisko interpreta';
export const FLOATING_GENRE_LABEL = 'Žáner';
export const CLUB_NAME = 'Názov klubu';
export const CLUB_TYPE = 'Typ klubu';
export const EVENT_NAME = 'Názov eventu';
export const CREATE_EVENT = 'Vytvorenie eventu';
export const DELETE_ME_AS_EVENT_PERFORMER = 'Odstrániť ma ako účastníka';
export const NEXT = 'Ďalej';
export const PUBLISH_TIMES_OF_PERFORMANCES = 'Zverejniť časy vystúpení';
export const BACK = 'Späť';
export const OK = 'OK';
export const SUCCESSFULLY_REMOVED_EVENT_PERFORMER = 'Úspešne si sa odstránil ako účastník eventu.';
export const ERROR_REMOVE_EVENT_PERFORMER = 'Nastala chyba pri odstraňovaní Teba ako účastníka eventu.';
export const CREATE_EVENT_BUTTON = 'Pridať event';
export const SAVE_DATA_BUTTON = 'Uložiť dáta';
export const SAVE_BUTTON = 'Uložiť';
export const REQUIRED_INFORMATION = 'Tento údaj je povinný';
export const EVENT_CATEGORY = 'Kategória eventu';
export const SHOW_MAP = 'Zobraziť mapu';
export const HIDE_MAP = 'Skryť mapu';
export const LOADING = 'Načítavam ...';
export const NO_OPTIONS_MESSAGE = 'Žiadne výsledky';
export const PLACEHOLDER_PERFORMERS = 'Vystupujúci';
export const PERFOMER_CREATE = 'Vytvoriť';
export const MUSIC_GENRE = 'Hudobný žáner';
export const START_DATE_TIME_EVENT = 'Dátum a čas začiatku eventu';
export const END_DATE_TIME_EVENT = 'Dátum a čas konca eventu';
export const CANCEL = 'Zrušiť';
export const NOTES = 'Poznámka';
export const PLACE_AND_NAME_OF_ORGANIZER = 'Miesto a názov organizátora';
export const PERFORMERS = 'Účastníci';
export const ORGANIZER_NAME = 'Meno organizátora';
export const FINANCE = 'Financie';
export const CHANGE_SUM_ENTRY = 'Upraviť vstupné';
export const ENTRY = 'Vstupné';
export const FROM = 'Od';
export const TO = 'Do';
export const LIST_OF_PERFORMERS = 'Zoznam účinkujúcich';
export const CHANGE_LIST_OF_PERFORMERS = 'Upraviť zoznam účinkujúcich';
export const EVENT_PLACE_AND_ORGANIZER = 'Miesto eventu a organizátor';
export const INTERPRET_NAME_BAND = 'Meno interpreta';
export const DATE = 'Dátum';
export const ADD = 'Pridať';
export const DELETE = 'Zmazať';
export const MAIN_EVENT_PHOTO = 'Hlavná fotografia eventu';
export const EVENT_DATE_AND_TIME = 'Dátum a čas eventu';
export const MAIN_PHOTO = 'Hlavná fotografia';
export const EVENT_PHOTOGALLERY = 'Fotogaléria eventu';
export const IMAGE_PHOTOGALLERY = 'Galéria obrázkov';
export const ADDITIONAL_EVENT_INFORMATION = 'Doplňujúce informácie o evente';
export const ADDITIONAL_INFORMATION = 'Doplňujúce informácie';
export const INFORMATION = 'Informácie';
export const LOGIN_TRANSLATE = 'Prihlásenie';
export const LOGIN_USER = 'Prihlásiť sa';
export const CLOSE = 'Zavrieť';
export const PASSWORD = 'Heslo';
export const EMAIL = 'E-mail';
export const GROUP_TITLE_PAYMENT = 'Názov skupiny';
export const PRICE_PAYMENT = 'Cena';
export const PRICE_FROM_PAYMENT = 'Cena od';
export const PRICE_TO_PAYMENT = 'Cena do';
export const REQUIRED_TEXT_FIELD = 'pole je povinné';
export const REQUIRED_TEXT_FIELD_LOWERCASE = 'pole je povinné';
export const ADD_PERFORMER = 'Pridať';
export const INTERPRET = 'Interpret';
export const BAD_INPUT_FINANCE_LIST = 'Minimálne 1 vstupné je povinné.';
export const BAD_GUEST_DATE_AT_EVENT = 'Dátumy interpretov musia byť v rozmedzí trvania eventu.';
export const GUEST_STARTS_BEFORE_EVENT_START = 'Interpreti nemôžu začať skôr ako začína event.';
export const DATE_END_SHOULD_BE_AFTER_DATE_START = 'Dátum konca eventu musí byť po jeho začiatku.';
export const TIME_END_SHOULD_BE_AFTER_TIME_START = 'Čas konca eventu musí byť po jeho začiatku.';
export const LOGIN_TEXT = 'Prihlásiť';
export const REGISTER_TEXT = 'Registrovať';
export const PASSWORD_NOT_SAME = 'Heslá sa nezhodujú';
export const PASSWORD_REQUIRED = 'Heslo je povinné';
export const ERROR_REGISTER_FORM = 'Pri registrácii nastala chyba';
export const EMAIL_ALREADY_USED = 'E-mail sa už používa';
export const CONTENT_LOADING = 'Overujem';
export const REGISTRATION_SUCCESS_TEXT = 'Úspech';
export const REGISTRATION_ERROR_TEXT = 'Menšia chyba';
export const REGISTRATION_ERROR_SUBTITLE = 'Vyplň prosím formulár tak aby bolo všetko zelené.';
export const REGISTRATION_SUCCESS_SUBTITLE = 'Pokračuj uložením a dostaneš sa do <span style="color: #07b26c; display: inline; font-weight: 500;">sveta Eventeeko</span>';
export const REGISTER_FLOATING_LABEL_EMAIL = 'E-mail';
export const CONFIRMATION_WITH_REGISTRATION = 'Registráciou dávaš najavo, že si sa oboznámil a súhlasíš s';
export const REGISTER_REGISTRATION = 'Registrácia';
export const REGISTER_BUSINESS_CONDITIONS = 'obchodnými podmienkami';
export const REGISTER_PRIVACY_POLICIES = 'zásadami pre ochranu osobných údajov';
export const REGISTER_AND = 'a';
export const THERE_ARE_NO_USER_EVENTS_AVAILABLE = 'Tento užívateľ nemá k dispozícii žiadne eventy';
export const THERE_ARE_NO_EVENTS_AVAILABLE = 'Neboli nájdené žiadne eventy podľa zadaných kritérií.';
export const EVENT_OF_USER = 'Eventy užívateľa';
export const REGISTER_REGISTER = 'Registrovať';
export const REGISTER_SUBTITLE_1 = 'Sme tu už cez';
export const REGISTER_SUBTITLE_3 = 'Poď k nám.';
export const IN_EVENTEEKO = 'v Eventeeko';
export const ICON_TEXT_LOGIN_BUTTON = 'Ikona prihláseného používateľa';
export const ICON_TEXT_REGISTER_BUTTON = 'Ikona registrácie používateľa';
export const REQUIRED_EMAIL = 'E-mail je povinný';
export const REQUIRED_PASSWORD = 'Heslo je povinné';
export const EMAIL_BAD_FORMAT = 'Zadali ste nesprávny formát emailu';
export const BAD_DATA_LOGIN_FORM = 'Zadali ste nesprávnu kombináciu údajov';
export const EVENT_SUCCESSFULLY_CREATED = 'Event bol úspešne vytvorený';
export const EVENT_ERROR_CREATED = 'Pri vytváraní eventu nastala chyba';
export const INTERPRET_TEXT_1 = 'Vstup do sveta Eventeeko';
export const INTERPRET_TEXT_2 = 'Vytvor svoj event';
export const INTERPRET_TEXT_3 = 'Prehľad Tvojich koncertov';
export const INTERPRET_TEXT_4 = 'Hodnotenie kvality a podmienok';
export const CLUB_TEXT_1 = 'Zviditeľni svoj event';
export const CLUB_TEXT_2 = 'Oslov verných aj nových zákazníkov svojim eventom';
export const CLUB_TEXT_3 = 'Prehľad termínov na eventy';
export const CLUB_TEXT_4 = 'Zvýš si rating';
export const PROMOTER_TEXT_1 = 'Vytvor vlastný event a inzeruj ihneď';
export const PROMOTER_TEXT_2 = 'Ponúkni svoje eventy';
export const PROMOTER_TEXT_3 = 'Oslov účastníkov na event';
export const PROMOTER_TEXT_4 = 'Prehľad v bookovaných akciách';
export const USER_TEXT_1 = 'Vstup do sveta Eventeeko';
export const USER_TEXT_2 = 'Sleduj eventy vo svojom meste';
export const USER_TEXT_3 = 'Navštevuj eventy rôznych typov';
export const USER_TEXT_4 = 'Jednoduchý a rýchly prehľad';
export const FREE_REGISTRATION = 'Registrácia zadarmo';
export const FREE_REGISTRATION_DESC = 'V Eventeeku môžeš pridávať svoje eventy hneď po registrácií. Nečakaj a vyber si z našich účtov.';
export const INTERPRET_TEXT = 'Interpret';
export const CLUB_TEXT = 'Klub';
export const PROMOTER_TEXT = 'Promotér';
export const USER_TEXT = 'Užívateľ';
export const VIAC_TEXT = 'Viac';
export const ADMISSION_TO_CONCERT_IS = 'Vstupné na koncert je';
export const FREE = 'Zdarma';
export const REGISTER_BUTTON = 'Registrovať';
export const PASSWORD_CONFIRMATION = 'Potvrdenie hesla';
export const FOOTER_COPYRIGHT_YEAR = new Date().getFullYear();
export const COORDINATES_REQUIRED = 'Zemepisná šírka a dĺžka je povinná';
export const STREET_REQUIRED = 'Adresa je povinná';
export const COUNTRY_REQUIRED = 'Krajina je povinná';
export const FOOTER_PROJECT_NAME = 'Eventeeko';
export const FOOTER_ALL_RIGHTS_RESERVED = 'Všetky práva vyhradené';
export const CLOSE_MODAL = 'Zavrieť';
export const LOGIN_CONTENT_JOIN_US = 'Pripoj sa k nám';
export const LOGIN_CONTENT_TITLE_2 = 'a objav <span style="color: #07b26c; display: inline;">svet</span> Eventeeko';
export const REGISTER_CONTENT_SAVE = 'Uložiť';
export const REGISTER_CONTENT_CLOSE = 'Ukončiť registráciu';
export const REGISTRATION_HELLO = 'Ahoj,';
export const REGISTRATION_SUBTITLE_1 = 'Ostáva Ti už len jeden krok k dokončeniu registrácie a vstúp do <span style="color: #07b26c; display: inline;">sveta Eventeeko</span>';
export const REGISTRATION_SUBTITLE_2 = '';
export const REGISTRATION_USERNAME = 'Užívateľské meno';
export const LOADING_CONTENT = 'Načítavam obsah';
export const LOADING_EVENTS = 'Načítavam eventy';
export const LOADING_EVENT_DATA = 'Načítavam dáta eventu';
export const LOADING_EVENTS_WHERE_IS_USER_AS_PERFORMER = 'Načítavam eventy, na ktorých pôsobíš ako účastník eventu';
export const LOADING_USER_EVENTS = 'Načítavam eventy používateľa';
export const CONTENT_SAVE_AND_CONTINUE = 'Uložiť a pokračovať';
export const CONTENT_SAVING = 'Ukladám';
export const LOGIN_CONTENT_SUBTITLE_1 = 'Spoznávaj, navštevuj a nájdi';
export const LOGIN_CONTENT_SUBTITLE_2 = 'všetky';
export const LOGIN_CONTENT_SUBTITLE_3 = 'eventy na jednom mieste.';
export const LOGIN_FLOATING_LABEL_PASSWORD = 'Heslo';
export const LOGIN_FLOATING_LABEL_EMAIL = 'E-mail';
export const ALT_ICON = 'ikona';
export const GENRE = 'Žáner eventu';
export const GENRE_CLUB = 'Žáner klubu';
export const TYPE_CLUB = 'Typ klubu';
export const PASSWORD_VISIBLE_ICON = '';
export const REGISTRATION_OPEN_MAP_MODAL_TEXT = 'Kliknutím sem otvoríš okno s možnosťou výberu Tvojho sídla';
export const REGISTRATION_CHANGE_MAP_LOCATION = 'Zmeniť lokáciu';
export const REGISTRATION_ADD_MAP_LOCATION = 'Pridať lokáciu';
export const CHOOSE_LOCATION_OF_CLUB = 'Vyber alebo označ oblasť v ktorej sídli Tvoj klub';
export const CHOOSE_LOCATION_OF_EVENT = 'Vyber alebo označ oblasť v ktorej sa uskutoční event';
export const BACK_TO_EVENT_FORM = 'Event formulár';
export const REGISTRATION_MODAL_MAP_INTRO_TEXT = 'Vyber si Tvoje miesto podnikania. Zadaj prosím názov lokácie alebo si prispôsob lokáciu pomocou potiahnutia značky na mape.';
export const MODAL_MAP_INTRO_TEXT = 'Kde sa bude event konať? Jednoducho zvoľ miesto konania Tvojho eventu.';
export const REGISTRATION_MODAL_MAP_INTRO_QUESTION_1 = 'Ako to funguje?';
export const REGISTRATION_MODAL_MAP_INTRO_QUESTION_2 = 'Viac info'; 
export const MODAL_MAP_INTRO_QUESTION_1 = 'Ako to funguje?';
export const MODAL_MAP_INTRO_QUESTION_2 = 'Viac info';
export const MODAL_MAP_TEXT_INFO_HOW_IT_WORKS = 'Text ako to funguje';
export const REGISTRATION_STREET_TITLE = 'Ulica:';
export const REGISTRATION_CITY_TITLE = 'Mesto:';
export const REGISTRATION_COUNTRY_TITLE = 'Krajina:';
export const SEARCHBOX_INPUT_CHOOSE_ADDRESS = 'Zadajte adresu';
export const REGISTER_LEAVE_QUESTION_MODAL_LOGOUT = 'Odhlásiť sa';
export const REGISTER_LEAVE_QUESTION_MODAL_DELETE_ACCOUNT = 'Deaktivovať účet';
export const UPDATE_EVENTS_BY_USER_UPDATE_MODAL = 'Úprava údajov eventov';
export const UPDATE_EVENTS_BY_USER_UPDATE_MODAL_TEXT = 'V tejto časti máš možnosť upraviť alebo vymazať svoje eventy.';
export const USER_HAS_NO_EVENTS_WHERE_IS_USER_AS_PERFORMER = 'Neboli nájdené žiadne eventy, kde by si pôsobil ako účastník eventu.';
export const DELETE_ACCOUNT = 'Deaktivovať účet';
export const SUCCESS_WITH_DELETE_ACCOUNT = 'Potvrdzujem deaktiváciu účtu';
export const ADD_CLUB_PHOTOS_MODAL_INTRO_TEXT = 'Priestor pod textom Ti umožní jednoducho vložiť jeden alebo viac obrázkov.';
export const ADD_CLUB_PHOTOS_MODAL_INTRO_QUESTION_1 = 'Ako vložiť obrázok?';
export const ADD_CLUB_PHOTOS_MODAL_INTRO_QUESTION_2 = 'Podmienky vloženia obrázku';
export const EVENT_TYPE = 'Typ eventu';
export const EVENT_GENRE_TYPE = 'Žáner eventu';
export const EVENT_TYPE_ALL = 'Všetky';

export const INFORMATION_BANNER_SENT_EMAIL = 'Tvoja registrácia prebehla úspešne. Na Tvoj zadaný e-mail Ti bol zaslaný aktivačný link, na ktorý je potrebné kliknúť, aby si mohol naplno používať Tvoj vytvorený účet na portáli Eventeeko.';
export const INFORMATION_BANNER_SUCCESSFUL_NOTIFICATION = 'Tvoj uživateľský účet bol úspešne aktivovaný. Môžete sa prihlásiť.';
export const INFORMATION_BANNER_ACTIVATION_LINK = 'Prihlásenie nebolo úspešné. Na úspešné prihlásenie a používanie Tvojho účtu je potrebné kliknúť na aktivačný link, ktorý bol zaslaný v e-maili.';
export const INFORMATION_BANNER_SUCCESSFUL_PASSWORD_CHANGED = 'Tvoje heslo bolo úspešne zmenené. Môžeš sa prihlásiť.';

export const TEXT_EVENT_HAS_ONE_LIKE = 'človeku sa páči event';
export const TEXT_EVENT_HAS_LIKES = 'ľuďom sa páči event';
export const FORGOTTEN_PASSWORD = 'Zabudnuté heslo';

export const EVENT_LIST_TITLE_SECTION = 'Všetky eventy';
export const EVENT_MY_LIST_TITLE_SECTION = 'Moje eventy';
export const EVENT_LIST_DESCRIPTION_SECTION = '';

export const SHOW_MY_EVENTS = 'Len moje eventy';
export const SHOW_EVENTS_OF_USER = 'Eventy používateľa'

export const EVENT_DATA_MANAGEMENT_MODAL_TITLE = 'Úprava údajov eventu';
export const CHANGE_PASSWORD_BUTTON = 'Zmeniť heslo';
export const PASSWORD_SUCCESSFULLY_CHANGED = 'Úspešne ste zmenili svoje heslo.';
export const CHANGING_PASSWORD_ERROR = 'Nastala chyba pri zmene hesla.';

export const ERROR_WHEN_REMOVING_USER = 'Došlo k chybe pri deaktivácií účtu.';
export const SUCCESS_USER_REMOVAL = 'Úspešne si deaktivoval svoj účet.';
export const SUCCESS_REMOVE_EVENT = 'Úspešne si odstránil event.';
export const ERROR_REMOVE_EVENT = 'Došlo k chybe pri odstraňovaní eventu.';
export const CHANGE_PASSWORD_HEADER = 'Zmena hesla';
export const CHANGE_PASSWORD_TEXT = 'V tejto sekcii si môžeš zmeniť heslo.';
export const ACCOUNT_SETTINGS_HEADER = 'Všeobecné nastavenia účtu';
export const ACCOUNT_SETTINGS_TEXT = 'V tejto sekcii si môžeš deaktivovať svoj účet na portáli Eventeeko.';
export const ACCOUNT_SETTINGS_MODAL_HEADER = 'Všeobecné nastavenia účtu';
export const ACCOUNT_SETTINGS_MODAL_TEXT = 'V tejto sekcii si môžeš deaktivovať svoj účet na portáli Eventeeko.';
export const CHANGE_PASSWORD_MODAL_HEADER = 'Zmena hesla';
export const CHANGE_PASSWORD_MODAL_TEXT = 'V tejto sekcii môžeš zmeniť heslo.';

export const REGISTRATION_STEP_1_INTERPRET = 'Meno interpreta';
export const REGISTRATION_STEP_2_INTERPRET = 'Tvoj žáner';
export const REGISTRATION_STEP_3_INTERPRET = 'Štát, z ktorého pochádzaš';
export const REGISTRATION_STEP_4_INTERPRET = 'Mesto, z ktorého pochádzaš';
export const REGISTRATION_STEP_1_USER = 'Najskôr zadaj svoje krstné meno';
export const REGISTRATION_STEP_2_USER = 'Zadaj svoje priezvisko';
export const REGISTRATION_STEP_3_USER = 'Aké si pohlavie?';
export const REGISTRATION_STEP_4_USER = 'Aký žáner preferuješ?';
export const REGISTRATION_STEP_5_USER = 'Štát, z ktorého pochádzaš';
export const REGISTRATION_STEP_6_USER = 'Mesto, z ktorého pochádzaš';
export const REGISTRATION_STEP_1_CLUB = 'Názov tvojho klubu';
export const REGISTRATION_STEP_2_CLUB = 'Žáner tvojho klubu';
export const REGISTRATION_STEP_3_CLUB = 'Aký je tvoj typ klubu?';
export const REGISTRATION_STEP_4_CLUB = 'Kde sídlite?';
export const REGISTRATION_STEP_5_CLUB = 'Popíš svoj klub.';
export const REGISTRATION_STEP_1_PROMOTER = 'Zadaj meno a priezvisko alebo názov spoločnosti';
export const REGISTRATION_STEP_2_PROMOTER = 'Štát, z ktorého pochádzaš';
export const REGISTRATION_STEP_3_PROMOTER = 'Mesto, z ktorého pochádzaš';
export const CURRENT_PASSWORD = 'Staré heslo';
export const PLAIN_PASSWORD = 'Nové heslo';
export const PLAIN_PASSWORD_AGAIN = 'Nové heslo znova';

export const REGISTER_LEAVE_QUESTION_MODAL_TITLE_1 = 'Odchádzaš natrvalo';
export const REGISTER_LEAVE_QUESTION_MODAL_TITLE_2 = 'alebo sa ešte vrátiš?';
export const REGISTER_LEAVE_QUESTION_MODAL_SUBTITLE_1 = 'Nechceš prísť aj o tieto výhody? Vráť sa k nám inokedy!';
export const REGISTER_LEAVE_QUESTION_MODAL_POINT_1 = 'Účet a prezeranie eventov ';
export const REGISTER_LEAVE_QUESTION_MODAL_POINT_1_SEC_2 = 'zadarmo';
export const REGISTER_LEAVE_QUESTION_MODAL_POINT_2 = 'Zábava a komunikácia s priateľmi';
export const BACK_TO_REGISTER_FORM = 'Registračný formulár';
export const SAVE_AND_CONTINUE_IN_REGISTRATION = 'Pokračovať v registrácii';
export const SAVE_AND_CONTINUE_IN_EVENT_CREATION = 'Uložiť adresu';
export const CLUB = 'Klub';
export const EVENTEEKO = 'Eventeeko';
export const FOOTER_SUBTITLE = 'Slovenské a české eventy na jednom mieste';
export const FOOTER_REGISTRATION_TITLE = 'Registrácia';
export const FOOTER_LINK_CLUB = 'Klub';
export const FOOTER_LINK_INTERPRET = 'Interpret';
export const FOOTER_LINK_PROMOTER = 'Promotér';
export const FOOTER_LINK_USER = 'Užívateľ';
export const FOOTER_IMPORTANT_LINKS = 'Dôležité odkazy';
export const FOOTER_BUSINESS_CONDITIONS = 'Obchodné podmienky';
export const FOOTER_PRIVACY_POLICIES = 'Ochrana osobných údajov';
export const LOGIN_CONTENT_FAQ = 'Časté otázky';
export const LOGIN_CONTENT_FORGOTTEN_PASSWORD = 'Zabudol si svoje heslo?';
export const LOGIN_CONTENT_LOGIN = 'Prihlásiť';
export const REGISTRATION_MODAL_TITLE = 'Registrácia';
export const USER_TRANSLATE = 'Používateľ';
export const PROMOTER_TRANSLATE = 'Promóter';
export const CLUB_TRANSLATE = 'Klub';
export const MENU_CALENDAR = 'Kalendár';
export const MENU_CLUBS = 'Kluby';
export const MENU_EVENTS = 'Eventy';
export const MENU_CONCERT = 'Koncert';
export const MENU_DASHBOARD = 'Prehľad';
export const MENU_BANDS = 'Interpreti';
export const MENU_EVENT = 'Môj event';
export const MENU_MESSAGES = 'Správy';
export const MY_PROFILE = 'Môj profil';
export const MENU_NOTIFICATIONS = 'Notifikácie';
export const MENU_MY_CONCERT = 'Môj koncert';
export const MENU_MY_EVENTS = 'Moje eventy';
export const MENU_CONCERTS = 'Koncerty';
export const MENU_LOGOUT = 'Odhlásenie';
export const MENU_INFO = 'Info';
export const CALENDAR = 'Kalendár';
export const MORE_FOLLOWERS = 'Viac sledovateľov';
export const ACCOUNT_CREATED_FROM = 'Účet od:';
export const ABOUT = 'O';
export const PROFILE_PICTURE_CHANGE_HEADER = 'Zmena profilovej fotografie';
export const COVER_PICTURE_CHANGE_HEADER = 'Zmena titulnej fotografie';
export const COVER_PICTURE_CHANGE_TEXT = 'Kliknutím na túto sekciu si môžeš zmeniť svoju titulnú fotografiu profilu.';
export const PHOTO_UPDATE_MODAL_CONFIRMATION = 'Potvrdiť a zmeniť profilovú fotografiu';
export const COVER_PHOTO_UPDATE_MODAL_CONFIRMATION = 'Potvrdiť a zmeniť titulnú fotografiu';
export const IMAGE_INSERTION = 'Vložiť obrázok';
export const NEW_ARTICLES = 'Najnovšie príspevky';
export const CHANGE_PHOTO = 'Zmeniť';
export const CHANGE_COVER_PHOTO = 'Zmeniť titulnú fotografiu';
export const PHOTO_UPLOAD_DESCRIPTION = 'Jednoduchá a intuitívna zmena a úprava Tvojej profilovej fotografie. Vlož alebo presuň fotografiu do vyznačenej oblasti.';
export const COVER_PHOTO_UPLOAD_DESCRIPTION = 'Jednoduchá a intuitívna zmena a úprava Tvojej titulnej fotografie. Vlož alebo presuň fotografiu do vyznačenej oblasti.';
export const CLOSE_AND_REJECT_MODAL = 'Zavrieť a ukončiť úpravy';
export const INSERT_ANOTHER_IMAGE = 'Vložit iný obrázok';
export const PREPARING_PHOTO_PLEASE_WAIT = 'Ukladám a pripravujem novú fotografiu';
export const REMOVING_ACCOUNT_PLEASE_WAIT = 'Deaktivujem účet a jeho súčasti';
export const CHANGE_TITLE_PHOTO = 'Zmeniť titulnú fotografiu';
export const ADD_EVENT = 'Pridať event';
export const CLUB_DESCRIPTION = 'Popis klubu';
export const FOOTER_PROJECT_NAME_PART_1 = 'Event';
export const FOOTER_PROJECT_NAME_PART_2 = 'eeko';
export const FOOTER_STATS_STAT_1_NUMBER = '25 523';
export const FOOTER_STATS_STAT_2_NUMBER = '254';
export const FOOTER_STATS_STAT_3_NUMBER = '473';
export const FOOTER_STATS_STAT_1_TEXT = 'eventov';
export const FOOTER_STATS_STAT_2_TEXT = 'užívateľov';
export const FOOTER_STATS_STAT_3_TEXT = 'návštev denne';
export const FOOTER_STATS_TITLE = 'Neváhaj a pridaj svoj event ešte dnes';
export const FOOTER_STATS_TITLE_USER_ROLE = 'Neváhaj a zúčastni sa nezabudnuteľných eventov';
export const EVENT_MODAL_CONTENT_ADD_PHOTO_EVENT = 'Vybrať foto';
export const EVENT_MODAL_CONTENT_LABEL_START_EVENT = 'Začiatok eventu';
export const EVENT_MODAL_CONTENT_LABEL_END_EVENT = 'Koniec eventu';
export const EVENT_MODAL_CONTENT_CHANGE_PHOTO_EVENT = 'Zmeniť';
export const CHANGE_PHOTOS_GALLERY = 'Zmeniť fotografie v galérii';
export const ADD_PHOTOS_GALLERY = 'Pridať fotografie';
export const EVENT_PLACE_TITLE = 'Miesto';
export const EVENT_STREET_TITLE = 'Ulica';
export const EVENT_CITY_TITLE = 'Mesto';
export const EVENT_COUNTRY_TITLE = 'Krajina';
export const EVENT_CHANGE_MAP_LOCATION = 'Zmeniť miesto';
export const EVENT_ADD_MAP_LOCATION = 'Pridať miesto';
export const PLACE_FOR_ADDING_PHOTO = 'Priestor pre vloženie fotografií';
export const PLACE_FOR_ADDING_CLUB_PHOTO = 'Priestor pre vloženie fotografií klubu';
export const EVENT_MODAL_GALLERY_INTRO_TEXT = 'Priestor pod textom Ti umožní jednoducho vložiť jeden alebo viac obrázkov, ktoré budú to práve pre Tvoje eventy';
export const EVENT_MODAL_GALLERY_INTRO_QUESTION_1 = 'Ako vložiť obrázok?';
export const EVENT_MODAL_GALLERY_INTRO_QUESTION_2 = 'Podmienky vloženia obrázku';
export const GALLERY_PHOTOS = 'Galéria fotografií';
export const GALLERY = 'Galéria';
export const TEXT_INSERT_FILE = "Vložiť súbory";
export const TEXT_LOADING = "Nahrávam ...";
export const TEXT_SUCCESSFULLY_INSERTED_EVENT = "Úspešne si vytvoril event";
export const TEXT_ERROR_INSERTED_EVENT = "Nastala chyba pri ukladaní eventu";
export const TEXT_INSERTING_EVENT_WAIT = "Pridávam event, prosím čakajte";
export const TEXT_EDITING_EVENT_WAIT = "Upravujem event, prosím čakajte";
export const TEXT_SUCCESSFULLY_EDITED_EVENT = "Úspešne si upravil event";
export const TEXT_ERROR_EDITED_EVENT = "Nastala chyba pri úprave eventu";
export const UPLOAD_SUBMITTING = 'Nahrávam obrázok';
export const UPLOAD_DELETE = 'Obrázok vymazaný';
export const UPLOAD_DELETE_ERROR = 'Nastala chyba pri vymazaní obrázka';
export const UPLOAD_CANCEL = 'Zrušili ste upload obrázka';
export const UPLOAD_REJECTED = 'Nastala chyba';
export const UPLOAD_SUCCESS = 'Obrázok bol úspešne publikovaný';
export const UPLOAD_ERROR = 'Nastala chyba pri publikovaní obrázku';
export const UPLOAD_RETRYING_UPLOAD = 'Nastala chyba, skúšam !znova';
export const REQUIRED_STREET_STRING = 'Pole ulica je povinné';
export const REQUIRED_PLACE_STRING = 'Pole mesto je povinné';
export const REQUIRED_COUNTRY_STRING = 'Pole krajina je povinné';
export const EVENT_START_DATE_INVALID_SMALLER_THAN_CURRENT_DATE = 'Dátum a čas začiatku eventu nesmie byť menší ako aktuálny';
export const EVENT_START_DATE_INVALID_BIGGER_THAN_END_DATE = 'Event nesmie začínať neskôr ako končí';
export const ORGANIZER_DESCRIPTION = 'Popis organizátora';
export const ORGANIZER = 'Organizátor';
export const USER_DATA_UPDATE_TITLE ='Úprava dát používateľa';
export const USER_DATA_UPDATE_FIRST_NAME = 'Krstné meno';
export const USER_DATA_UPDATE_SURNAME = 'Priezvisko';
export const USER_DATA_UPDATE_COUNTRY_GET_EVENTS = 'Štát, z ktorého pochádzaš';
export const USER_DATA_UPDATE_GENDER = 'Pohlavie';
export const USER_DATA_UPDATE_GENRE = 'Žáner tvojho klubu';
export const USER_DATA_UPDATE_FAVOURITE_GENRE = 'Tvoj obľúbený žáner';
export const USER_DATA_UPDATE_CLUB_CITY = 'Mesto';
export const USER_DATA_UPDATE_CITY = 'Mesto, z ktorého pochádzaš';
export const USER_DATA_UPDATE_COMPANY = 'Meno interpreta';
export const USER_DATA_UPDATE_COMPANY_OR_INTERPRET = 'Meno interpreta';
export const USER_DATA_UPDATE_CLUB_TITLE = 'Názov tvojho klubu';
export const USER_DATA_UPDATE_STREET = 'Ulica';
export const USER_DATA_UPDATE_COUNTRY = 'Krajina';
export const CLUB_ADDRESS = 'Adresa klubu';
export const USER_DATA_UPDATE_CLUB_ADDRESS = 'Adresa klubu';
export const USER_DATA_UPDATE_CLUB_DESCRIPTION = 'Popis klubu';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL = 'Úprava osobných údajov a údajov používateľa';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT = 'V tejto časti si môžeš upraviť údaje ako meno, priezvisko, názov klubu, obľúbený žáner.';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_USER = 'V tejto časti si môžeš upraviť meno a priezvisko, alebo svoj obľúbený žáner.';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_CLUB = 'V tejto časti si môžeš upraviť názov klubu, alebo jeho žáner.';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_INTERPRET = 'V tejto časti si môžeš upraviť svoje meno a priezvisko, meno interpreta, alebo svoj hudobný žáner.';
export const USER_DATA_MANAGEMENT_UPDATE_MODAL_TEXT_PROMOTER = 'V tejto časti máš možnosť si upraviť svoje meno a priezvisko, alebo názov obchodnej spoločnosti.';
export const USER_AS_PERFORMER_OF_EVENTS_UPDATE_MODAL = 'Pôsobenie ako účastník eventu';
export const USER_AS_PERFORMER_OF_EVENTS_UPDATE_MODAL_TEXT = 'Sekcia obsahuje zoznam eventov, v ktorých si označený ako účastník. Máš možnosť odstrániť seba ako účastníka eventu.';
export const CLOSE_EVENTS_BY_USER_MODAL = 'Zatvoriť zoznam eventov';
export const CLOSE_EVENTS_BY_PERFORMER_MODAL = 'Zatvoriť';
export const EVENTS_BY_USER_MODAL_TITLE = 'Moje eventy';
export const EVENTS_BY_USER_MODAL_TEXT = 'V tejto sekcii sa nachádzajú všetky Tvoje eventy zoradené podľa kategórií, ktoré môžeš jednoducho upravovať podľa svojich predstáv.';
export const EVENT_DETAIL_SIMILAR_EVENTS = 'Podobné eventy';
export const EVENT_DETAIL_EVENT_LOCATION = 'Lokalita';
export const EVENT_DETAIL_EVENT_LOCATION_CONTENT = 'Event sa koná na ulici';
export const EVENTS_BY_PERFORMER_MODAL_TITLE = 'Eventy, na ktorých pôsobím ako účastník';
export const EVENTS_BY_PERFORMER_MODAL_TEXT = 'V tejto sekcii máš možnosť sa odstrániť ako účastník z eventov.';
export const USER_HAS_NO_EVENTS = 'Nemáš vytvorené žiadne eventy';  
export const ADD_CLUB_PHOTOS_HEADER = 'Pridanie fotografií klubu';
export const ADD_CLUB_PHOTOS_TEXT = 'V tejto sekcii je možné pridávať fotografie Tvojho klubu.';
export const LOADING_LIST_OF_CLUBS = 'Načítavam zoznam klubov';
export const LOADING_LIST_OF_BANDS = 'Načítavam zoznam kapiel';
export const CLUBS = 'Kluby';
export const BANDS = 'Interpreti';
export const LOADING_TOP_EVENTS = 'Načítavam top eventy';
export const THERE_ARE_NO_TOP_EVENTS_AVAILABLE = 'Nie sú k dispozícii žiadne top eventy';
export const HISTORIC_EVENT = 'Event sa konal';
export const LABEL_DATE_STARTED = 'Dátum vzniku';
export const LABEL_GENRE = 'Žáner';
export const LABEL_COUNTRY = 'Krajina';
export const LABEL_ADDRESS = 'Adresa';
export const ADD_PHOTOS_HEADER = 'Pridanie fotografií';
export const ADD_PHOTOS_TEXT = 'V tejto sekcii je možné pridávať fotografie.';
export const ADD_PHOTOS_MODAL_INTRO_TEXT = 'Priestor pod textom Ti umožní jednoducho vložiť jeden alebo viac obrázkov.';

export const SLOVAK_STATUSES_FILE_UPLOAD = {
    canceled: 'Zrušené',
    deleting: 'Mazanie...',
    paused: 'Pozastavené',
    queued: 'Vo fronte',
    retrying_upload: 'Opakovanie...',
    submitting: 'Odovzdávanie...',
    uploading: 'Nahrávanie...',
    upload_failed: 'Neúspešné',
    upload_successful: 'Úspešné'
};

export const GUEST_STARTS_BEFORE_EVENT_START_FUNC = interpret => {
    return interpret ? 'Interpret: '+ interpret +' nemôže začať skôr ako začína event.' : 'Interpreti nesmú začínať skôr ako event.';
}

export const GUEST_START_AFTER_EVENT_END_FUNC = interpret => {
    return interpret ? 'Interpret: '+ interpret +' nemôže začať po konci eventu.' : 'Interpreti nemôžu začínať po konci eventu.';
}

export const GET_NUMBER_OF_EVENTS_IN_SLOVAK = pocet => {
    let sentence = '';
    if(pocet === 0 || pocet > 4)
        sentence = pocet + " eventov";
    else if (pocet === 1)
        sentence = pocet + " event";
    else 
        sentence = pocet + " eventy";
    return sentence;
}

const PROMOTER_NOMINATIV_SINGULAR = 'Promotér';
const PROMOTER_NOMINATIV_PLURAL = 'Promotéri';
const USER_NOMINATIV_SINGULAR = 'Užívateľ';
const USER_NOMINATIV_PLURAL = 'Užívatelia';
const CLUB_NOMINATIV_SINGULAR = 'Klub';
const CLUB_NOMINATIV_PLURAL = 'Kluby';
const INTERPRET_NOMINATIV_SINGULAR = 'Interpret';
const INTERPRET_NOMINATIV_PLURAL = 'Interpreti';

const PROMOTER_LOKAL_SINGULAR = 'promotérovi';
const PROMOTER_LOKAL_PLURAL = 'promotéroch';
const USER_LOKAL_SINGULAR = 'užívateľovi';
const USER_LOKAL_PLURAL = 'užívateľoch';
const CLUB_LOKAL_SINGULAR = 'klube';
const CLUB_LOKAL_PLURAL = 'kluboch';
const INTERPRET_LOKAL_SINGULAR = 'interpretovi';
const INTERPRET_LOKAL_PLURAL = 'interpretoch';

const PROMOTER_INSTRUMENTAL_SINGULAR = 'promotérom';
const PROMOTER_INSTRUMENTAL_PLURAL = 'promotérmi';
const USER_INSTRUMENTAL_SINGULAR = 'užívateľom';
const USER_INSTRUMENTAL_PLURAL = 'užívateľmi';
const CLUB_INSTRUMENTAL_SINGULAR = 'klubom';
const CLUB_INSTRUMENTAL_PLURAL = 'klubmi';
const INTERPRET_INSTRUMENTAL_SINGULAR = 'interpretom';
const INTERPRET_INSTRUMENTAL_PLURAL = 'interpretmi';

const PROMOTER_ACCUSATIVE_SINGULAR = 'promotéra';
const PROMOTER_ACCUSATIVE_PLURAL = 'promotérov';
const USER_ACCUSATIVE_SINGULAR = 'užívateľa';
const USER_ACCUSATIVE_PLURAL = 'užívateľov';
const CLUB_ACCUSATIVE_SINGULAR = 'klub';
const CLUB_ACCUSATIVE_PLURAL = 'kluby';
const INTERPRET_ACCUSATIVE_SINGULAR = 'interpreta';
const INTERPRET_ACCUSATIVE_PLURAL = 'interpretov';

const PROMOTER_GENITIVE_SINGULAR = 'promotéra';
const PROMOTER_GENITIVE_PLURAL = 'promotérov';
const USER_GENITIVE_SINGULAR = 'užívateľa';
const USER_GENITIVE_PLURAL = 'užívateľov';
const CLUB_GENITIVE_SINGULAR = 'klubu';
const CLUB_GENITIVE_PLURAL = 'klubov';
const INTERPRET_GENITIVE_SINGULAR = 'interpreta';
const INTERPRET_GENITIVE_PLURAL = 'interpretov';

export const GET_USERTYPE_IN_NOMINATIV = (type, multi=false) => {
    switch(type){
        case 1:
            return multi ? INTERPRET_NOMINATIV_PLURAL : INTERPRET_NOMINATIV_SINGULAR;
        case 2:
            return multi ? CLUB_NOMINATIV_PLURAL : CLUB_NOMINATIV_SINGULAR;
        case 3:
            return multi ? PROMOTER_NOMINATIV_PLURAL : PROMOTER_NOMINATIV_SINGULAR;
        case 4:
            return multi ? USER_NOMINATIV_PLURAL : USER_NOMINATIV_SINGULAR;
        default:
            return '';
    }
}

export const GET_USERTYPE_IN_LOKAL = (type, multi=false) => {
    switch(type){
        case 1:
            return multi ? INTERPRET_LOKAL_PLURAL : INTERPRET_LOKAL_SINGULAR;
        case 2:
            return multi ? CLUB_LOKAL_PLURAL : CLUB_LOKAL_SINGULAR;
        case 3:
            return multi ? PROMOTER_LOKAL_PLURAL : PROMOTER_LOKAL_SINGULAR;
        case 4:
            return multi ? USER_LOKAL_PLURAL : USER_LOKAL_SINGULAR;
        default:
            return '';
    }
}

export const GET_USERTYPE_IN_INSTRUMENTAL = (type, multi=false) => {
    switch(type){
        case 1:
            return multi ? INTERPRET_INSTRUMENTAL_PLURAL : INTERPRET_INSTRUMENTAL_SINGULAR;
        case 2:
            return multi ? CLUB_INSTRUMENTAL_PLURAL : CLUB_INSTRUMENTAL_SINGULAR;
        case 3:
            return multi ? PROMOTER_INSTRUMENTAL_PLURAL : PROMOTER_INSTRUMENTAL_SINGULAR;
        case 4:
            return multi ? USER_INSTRUMENTAL_PLURAL : USER_INSTRUMENTAL_SINGULAR;
        default:
            return '';
    }
}

export const GET_USERTYPE_IN_GENITIVE = (type, multi=false) => {
    switch(type){
        case 1:
            return multi ? INTERPRET_GENITIVE_PLURAL : INTERPRET_GENITIVE_SINGULAR;
        case 2:
            return multi ? CLUB_GENITIVE_PLURAL : CLUB_GENITIVE_SINGULAR;
        case 3:
            return multi ? PROMOTER_GENITIVE_PLURAL : PROMOTER_GENITIVE_SINGULAR;
        case 4:
            return multi ? USER_GENITIVE_PLURAL : USER_GENITIVE_SINGULAR;
        default:
            return '';
    }
}


export const GET_USERTYPE_IN_ACCUSATIVE = (type, multi=false) => {
    switch(type){
        case 1:
            return multi ? INTERPRET_ACCUSATIVE_PLURAL : INTERPRET_ACCUSATIVE_SINGULAR;
        case 2:
            return multi ? CLUB_ACCUSATIVE_PLURAL : CLUB_ACCUSATIVE_SINGULAR;
        case 3:
            return multi ? PROMOTER_ACCUSATIVE_PLURAL : PROMOTER_ACCUSATIVE_SINGULAR;
        case 4:
            return multi ? USER_ACCUSATIVE_PLURAL : USER_ACCUSATIVE_SINGULAR;
        default:
            return '';
    }
}
